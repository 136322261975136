@font-face {
  font-family: "Pe-icon-7-stroke";
  src: url("./../fonts/oblecmimiska-cz/Pe-icon-7-stroke.eot?d7yf1v");
  src: url("./../fonts/oblecmimiska-cz/Pe-icon-7-stroke.eot?#iefixd7yf1v") format("embedded-opentype"), url("./../fonts/oblecmimiska-cz/Pe-icon-7-stroke.woff?d7yf1v") format("woff"), url("./../fonts/oblecmimiska-cz/Pe-icon-7-stroke.ttf?d7yf1v") format("truetype"), url("./../fonts/oblecmimiska-cz/Pe-icon-7-stroke.svg?d7yf1v#Pe-icon-7-stroke") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=pe-7s-], [class*=" pe-7s-"] {
  display: inline-block;
  font-family: "Pe-icon-7-stroke";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pe-7s-album:before {
  content: "\e6aa";
}

.pe-7s-arc:before {
  content: "\e6ab";
}

.pe-7s-back-2:before {
  content: "\e6ac";
}

.pe-7s-bandaid:before {
  content: "\e6ad";
}

.pe-7s-car:before {
  content: "\e6ae";
}

.pe-7s-diamond:before {
  content: "\e6af";
}

.pe-7s-door-lock:before {
  content: "\e6b0";
}

.pe-7s-eyedropper:before {
  content: "\e6b1";
}

.pe-7s-female:before {
  content: "\e6b2";
}

.pe-7s-gym:before {
  content: "\e6b3";
}

.pe-7s-hammer:before {
  content: "\e6b4";
}

.pe-7s-headphones:before {
  content: "\e6b5";
}

.pe-7s-helm:before {
  content: "\e6b6";
}

.pe-7s-hourglass:before {
  content: "\e6b7";
}

.pe-7s-leaf:before {
  content: "\e6b8";
}

.pe-7s-magic-wand:before {
  content: "\e6b9";
}

.pe-7s-male:before {
  content: "\e6ba";
}

.pe-7s-map-2:before {
  content: "\e6bb";
}

.pe-7s-next-2:before {
  content: "\e6bc";
}

.pe-7s-paint-bucket:before {
  content: "\e6bd";
}

.pe-7s-pendrive:before {
  content: "\e6be";
}

.pe-7s-photo:before {
  content: "\e6bf";
}

.pe-7s-piggy:before {
  content: "\e6c0";
}

.pe-7s-plugin:before {
  content: "\e6c1";
}

.pe-7s-refresh-2:before {
  content: "\e6c2";
}

.pe-7s-rocket:before {
  content: "\e6c3";
}

.pe-7s-settings:before {
  content: "\e6c4";
}

.pe-7s-shield:before {
  content: "\e6c5";
}

.pe-7s-smile:before {
  content: "\e6c6";
}

.pe-7s-usb:before {
  content: "\e6c7";
}

.pe-7s-vector:before {
  content: "\e6c8";
}

.pe-7s-wine:before {
  content: "\e6c9";
}

.pe-7s-cloud-upload:before {
  content: "\e68a";
}

.pe-7s-cash:before {
  content: "\e68c";
}

.pe-7s-close:before {
  content: "\e680";
}

.pe-7s-bluetooth:before {
  content: "\e68d";
}

.pe-7s-cloud-download:before {
  content: "\e68b";
}

.pe-7s-way:before {
  content: "\e68e";
}

.pe-7s-close-circle:before {
  content: "\e681";
}

.pe-7s-id:before {
  content: "\e68f";
}

.pe-7s-angle-up:before {
  content: "\e682";
}

.pe-7s-wristwatch:before {
  content: "\e690";
}

.pe-7s-angle-up-circle:before {
  content: "\e683";
}

.pe-7s-world:before {
  content: "\e691";
}

.pe-7s-angle-right:before {
  content: "\e684";
}

.pe-7s-volume:before {
  content: "\e692";
}

.pe-7s-angle-right-circle:before {
  content: "\e685";
}

.pe-7s-users:before {
  content: "\e693";
}

.pe-7s-angle-left:before {
  content: "\e686";
}

.pe-7s-user-female:before {
  content: "\e694";
}

.pe-7s-angle-left-circle:before {
  content: "\e687";
}

.pe-7s-up-arrow:before {
  content: "\e695";
}

.pe-7s-angle-down:before {
  content: "\e688";
}

.pe-7s-switch:before {
  content: "\e696";
}

.pe-7s-angle-down-circle:before {
  content: "\e689";
}

.pe-7s-scissors:before {
  content: "\e697";
}

.pe-7s-wallet:before {
  content: "\e600";
}

.pe-7s-safe:before {
  content: "\e698";
}

.pe-7s-volume2:before {
  content: "\e601";
}

.pe-7s-volume1:before {
  content: "\e602";
}

.pe-7s-voicemail:before {
  content: "\e603";
}

.pe-7s-video:before {
  content: "\e604";
}

.pe-7s-user:before {
  content: "\e605";
}

.pe-7s-upload:before {
  content: "\e606";
}

.pe-7s-unlock:before {
  content: "\e607";
}

.pe-7s-umbrella:before {
  content: "\e608";
}

.pe-7s-trash:before {
  content: "\e609";
}

.pe-7s-tools:before {
  content: "\e60a";
}

.pe-7s-timer:before {
  content: "\e60b" 0;
}

.pe-7s-ticket:before {
  content: "\e60c";
}

.pe-7s-target:before {
  content: "\e60d";
}

.pe-7s-sun:before {
  content: "\e60e";
}

.pe-7s-study:before {
  content: "\e60f";
}

.pe-7s-stopwatch:before {
  content: "\e610";
}

.pe-7s-star:before {
  content: "\e611";
}

.pe-7s-speaker:before {
  content: "\e612";
}

.pe-7s-signal:before {
  content: "\e613";
}

.pe-7s-shuffle:before {
  content: "\e614";
}

.pe-7s-shopbag:before {
  content: "\e615";
}

.pe-7s-share:before {
  content: "\e616";
}

.pe-7s-server:before {
  content: "\e617";
}

.pe-7s-search:before {
  content: "\e618";
}

.pe-7s-film:before {
  content: "\e6a5";
}

.pe-7s-science:before {
  content: "\e619";
}

.pe-7s-disk:before {
  content: "\e6a6";
}

.pe-7s-ribbon:before {
  content: "\e61a";
}

.pe-7s-repeat:before {
  content: "\e61b";
}

.pe-7s-refresh:before {
  content: "\e61c";
}

.pe-7s-add-user:before {
  content: "\e6a9";
}

.pe-7s-refresh-cloud:before {
  content: "\e61d";
}

.pe-7s-paperclip:before {
  content: "\e69c";
}

.pe-7s-radio:before {
  content: "\e61e";
}

.pe-7s-note2:before {
  content: "\e69d";
}

.pe-7s-print:before {
  content: "\e61f";
}

.pe-7s-network:before {
  content: "\e69e";
}

.pe-7s-prev:before {
  content: "\e620";
}

.pe-7s-mute:before {
  content: "\e69f";
}

.pe-7s-power:before {
  content: "\e621";
}

.pe-7s-medal:before {
  content: "\e6a0";
}

.pe-7s-portfolio:before {
  content: "\e622";
}

.pe-7s-like2:before {
  content: "\e6a1";
}

.pe-7s-plus:before {
  content: "\e623";
}

.pe-7s-left-arrow:before {
  content: "\e6a2";
}

.pe-7s-play:before {
  content: "\e624";
}

.pe-7s-key:before {
  content: "\e6a3";
}

.pe-7s-plane:before {
  content: "\e625";
}

.pe-7s-joy:before {
  content: "\e6a4";
}

.pe-7s-photo-gallery:before {
  content: "\e626";
}

.pe-7s-pin:before {
  content: "\e69b";
}

.pe-7s-phone:before {
  content: "\e627";
}

.pe-7s-plug:before {
  content: "\e69a";
}

.pe-7s-pen:before {
  content: "\e628";
}

.pe-7s-right-arrow:before {
  content: "\e699";
}

.pe-7s-paper-plane:before {
  content: "\e629";
}

.pe-7s-delete-user:before {
  content: "\e6a7";
}

.pe-7s-paint:before {
  content: "\e62a";
}

.pe-7s-bottom-arrow:before {
  content: "\e6a8";
}

.pe-7s-notebook:before {
  content: "\e62b";
}

.pe-7s-note:before {
  content: "\e62c";
}

.pe-7s-next:before {
  content: "\e62d";
}

.pe-7s-news-paper:before {
  content: "\e62e";
}

.pe-7s-musiclist:before {
  content: "\e62f";
}

.pe-7s-music:before {
  content: "\e630";
}

.pe-7s-mouse:before {
  content: "\e631";
}

.pe-7s-more:before {
  content: "\e632";
}

.pe-7s-moon:before {
  content: "\e633";
}

.pe-7s-monitor:before {
  content: "\e634";
}

.pe-7s-micro:before {
  content: "\e635";
}

.pe-7s-menu:before {
  content: "\e636";
}

.pe-7s-map:before {
  content: "\e637";
}

.pe-7s-map-marker:before {
  content: "\e638";
}

.pe-7s-mail:before {
  content: "\e639";
}

.pe-7s-mail-open:before {
  content: "\e63a";
}

.pe-7s-mail-open-file:before {
  content: "\e63b";
}

.pe-7s-magnet:before {
  content: "\e63c";
}

.pe-7s-loop:before {
  content: "\e63d";
}

.pe-7s-look:before {
  content: "\e63e";
}

.pe-7s-lock:before {
  content: "\e63f";
}

.pe-7s-lintern:before {
  content: "\e640";
}

.pe-7s-link:before {
  content: "\e641";
}

.pe-7s-like:before {
  content: "\e642";
}

.pe-7s-light:before {
  content: "\e643";
}

.pe-7s-less:before {
  content: "\e644";
}

.pe-7s-keypad:before {
  content: "\e645";
}

.pe-7s-junk:before {
  content: "\e646";
}

.pe-7s-info:before {
  content: "\e647";
}

.pe-7s-home:before {
  content: "\e648";
}

.pe-7s-help2:before {
  content: "\e649";
}

.pe-7s-help1:before {
  content: "\e64a";
}

.pe-7s-graph3:before {
  content: "\e64b";
}

.pe-7s-graph2:before {
  content: "\e64c";
}

.pe-7s-graph1:before {
  content: "\e64d";
}

.pe-7s-graph:before {
  content: "\e64e";
}

.pe-7s-global:before {
  content: "\e64f";
}

.pe-7s-gleam:before {
  content: "\e650";
}

.pe-7s-glasses:before {
  content: "\e651";
}

.pe-7s-gift:before {
  content: "\e652";
}

.pe-7s-folder:before {
  content: "\e653";
}

.pe-7s-flag:before {
  content: "\e654";
}

.pe-7s-filter:before {
  content: "\e655";
}

.pe-7s-file:before {
  content: "\e656";
}

.pe-7s-expand1:before {
  content: "\e657";
}

.pe-7s-exapnd2:before {
  content: "\e658";
}

.pe-7s-edit:before {
  content: "\e659";
}

.pe-7s-drop:before {
  content: "\e65a";
}

.pe-7s-drawer:before {
  content: "\e65b";
}

.pe-7s-download:before {
  content: "\e65c";
}

.pe-7s-display2:before {
  content: "\e65d";
}

.pe-7s-display1:before {
  content: "\e65e";
}

.pe-7s-diskette:before {
  content: "\e65f";
}

.pe-7s-date:before {
  content: "\e660";
}

.pe-7s-cup:before {
  content: "\e661";
}

.pe-7s-culture:before {
  content: "\e662";
}

.pe-7s-crop:before {
  content: "\e663";
}

.pe-7s-credit:before {
  content: "\e664";
}

.pe-7s-copy-file:before {
  content: "\e665";
}

.pe-7s-config:before {
  content: "\e666";
}

.pe-7s-compass:before {
  content: "\e667";
}

.pe-7s-comment:before {
  content: "\e668";
}

.pe-7s-coffee:before {
  content: "\e669";
}

.pe-7s-cloud:before {
  content: "\e66a";
}

.pe-7s-clock:before {
  content: "\e66b";
}

.pe-7s-check:before {
  content: "\e66c";
}

.pe-7s-chat:before {
  content: "\e66d";
}

.pe-7s-cart:before {
  content: "\e66e";
}

.pe-7s-camera:before {
  content: "\e66f";
}

.pe-7s-call:before {
  content: "\e670";
}

.pe-7s-calculator:before {
  content: "\e671";
}

.pe-7s-browser:before {
  content: "\e672";
}

.pe-7s-box2:before {
  content: "\e673";
}

.pe-7s-box1:before {
  content: "\e674";
}

.pe-7s-bookmarks:before {
  content: "\e675";
}

.pe-7s-bicycle:before {
  content: "\e676";
}

.pe-7s-bell:before {
  content: "\e677";
}

.pe-7s-battery:before {
  content: "\e678";
}

.pe-7s-ball:before {
  content: "\e679";
}

.pe-7s-back:before {
  content: "\e67a";
}

.pe-7s-attention:before {
  content: "\e67b";
}

.pe-7s-anchor:before {
  content: "\e67c";
}

.pe-7s-albums:before {
  content: "\e67d";
}

.pe-7s-alarm:before {
  content: "\e67e";
}

.pe-7s-airplay:before {
  content: "\e67f";
}

@font-face {
  font-family: "themify";
  src: url("./../fonts/oblecmimiska-cz/themify.eot?d7yf1v2");
  src: url("./../fonts/oblecmimiska-cz/themify.eot?#d7yf1v23") format("embedded-opentype"), url("./../fonts/oblecmimiska-cz/themify.woff?d7yf1v2") format("woff"), url("./../fonts/oblecmimiska-cz/themify.ttf?d7yf1v2") format("truetype"), url("./../fonts/oblecmimiska-cz/themify.svg?d7yf1v#themify") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=ti-], [class*=" ti-"] {
  display: inline-block;
  font-family: "themify";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ti-wand:before {
  content: "\e600";
}

.ti-volume:before {
  content: "\e601";
}

.ti-user:before {
  content: "\e602";
}

.ti-unlock:before {
  content: "\e603";
}

.ti-unlink:before {
  content: "\e604";
}

.ti-trash:before {
  content: "\e605";
}

.ti-thought:before {
  content: "\e606";
}

.ti-target:before {
  content: "\e607";
}

.ti-tag:before {
  content: "\e608";
}

.ti-tablet:before {
  content: "\e609";
}

.ti-star:before {
  content: "\e60a";
}

.ti-spray:before {
  content: "\e60b";
}

.ti-signal:before {
  content: "\e60c";
}

.ti-shopping-cart:before {
  content: "\e60d";
}

.ti-shopping-cart-full:before {
  content: "\e60e";
}

.ti-settings:before {
  content: "\e60f";
}

.ti-search:before {
  content: "\e610";
}

.ti-zoom-in:before {
  content: "\e611";
}

.ti-zoom-out:before {
  content: "\e612";
}

.ti-cut:before {
  content: "\e613";
}

.ti-ruler:before {
  content: "\e614";
}

.ti-ruler-pencil:before {
  content: "\e615";
}

.ti-ruler-alt:before {
  content: "\e616";
}

.ti-bookmark:before {
  content: "\e617";
}

.ti-bookmark-alt:before {
  content: "\e618";
}

.ti-reload:before {
  content: "\e619";
}

.ti-plus:before {
  content: "\e61a";
}

.ti-pin:before {
  content: "\e61b";
}

.ti-pencil:before {
  content: "\e61c";
}

.ti-pencil-alt:before {
  content: "\e61d";
}

.ti-paint-roller:before {
  content: "\e61e";
}

.ti-paint-bucket:before {
  content: "\e61f";
}

.ti-na:before {
  content: "\e620";
}

.ti-mobile:before {
  content: "\e621";
}

.ti-minus:before {
  content: "\e622";
}

.ti-medall:before {
  content: "\e623";
}

.ti-medall-alt:before {
  content: "\e624";
}

.ti-marker:before {
  content: "\e625";
}

.ti-marker-alt:before {
  content: "\e626";
}

.ti-arrow-up:before {
  content: "\e627";
}

.ti-arrow-right:before {
  content: "\e628";
}

.ti-arrow-left:before {
  content: "\e629";
}

.ti-arrow-down:before {
  content: "\e62a";
}

.ti-lock:before {
  content: "\e62b";
}

.ti-location-arrow:before {
  content: "\e62c";
}

.ti-link:before {
  content: "\e62d";
}

.ti-layout:before {
  content: "\e62e";
}

.ti-layers:before {
  content: "\e62f";
}

.ti-layers-alt:before {
  content: "\e630";
}

.ti-key:before {
  content: "\e631";
}

.ti-import:before {
  content: "\e632";
}

.ti-image:before {
  content: "\e633";
}

.ti-heart:before {
  content: "\e634";
}

.ti-heart-broken:before {
  content: "\e635";
}

.ti-hand-stop:before {
  content: "\e636";
}

.ti-hand-open:before {
  content: "\e637";
}

.ti-hand-drag:before {
  content: "\e638";
}

.ti-folder:before {
  content: "\e639";
}

.ti-flag:before {
  content: "\e63a";
}

.ti-flag-alt:before {
  content: "\e63b";
}

.ti-flag-alt-2:before {
  content: "\e63c";
}

.ti-eye:before {
  content: "\e63d";
}

.ti-export:before {
  content: "\e63e";
}

.ti-exchange-vertical:before {
  content: "\e63f";
}

.ti-desktop:before {
  content: "\e640";
}

.ti-cup:before {
  content: "\e641";
}

.ti-crown:before {
  content: "\e642";
}

.ti-comments:before {
  content: "\e643";
}

.ti-comment:before {
  content: "\e644";
}

.ti-comment-alt:before {
  content: "\e645";
}

.ti-close:before {
  content: "\e646";
}

.ti-clip:before {
  content: "\e647";
}

.ti-angle-up:before {
  content: "\e648";
}

.ti-angle-right:before {
  content: "\e649";
}

.ti-angle-left:before {
  content: "\e64a";
}

.ti-angle-down:before {
  content: "\e64b";
}

.ti-check:before {
  content: "\e64c";
}

.ti-check-box:before {
  content: "\e64d";
}

.ti-camera:before {
  content: "\e64e";
}

.ti-announcement:before {
  content: "\e64f";
}

.ti-brush:before {
  content: "\e650";
}

.ti-briefcase:before {
  content: "\e651";
}

.ti-bolt:before {
  content: "\e652";
}

.ti-bolt-alt:before {
  content: "\e653";
}

.ti-blackboard:before {
  content: "\e654";
}

.ti-bag:before {
  content: "\e655";
}

.ti-move:before {
  content: "\e656";
}

.ti-arrows-vertical:before {
  content: "\e657";
}

.ti-arrows-horizontal:before {
  content: "\e658";
}

.ti-fullscreen:before {
  content: "\e659";
}

.ti-arrow-top-right:before {
  content: "\e65a";
}

.ti-arrow-top-left:before {
  content: "\e65b";
}

.ti-arrow-circle-up:before {
  content: "\e65c";
}

.ti-arrow-circle-right:before {
  content: "\e65d";
}

.ti-arrow-circle-left:before {
  content: "\e65e";
}

.ti-arrow-circle-down:before {
  content: "\e65f";
}

.ti-angle-double-up:before {
  content: "\e660";
}

.ti-angle-double-right:before {
  content: "\e661";
}

.ti-angle-double-left:before {
  content: "\e662";
}

.ti-angle-double-down:before {
  content: "\e663";
}

.ti-zip:before {
  content: "\e664";
}

.ti-world:before {
  content: "\e665";
}

.ti-wheelchair:before {
  content: "\e666";
}

.ti-view-list:before {
  content: "\e667";
}

.ti-view-list-alt:before {
  content: "\e668";
}

.ti-view-grid:before {
  content: "\e669";
}

.ti-uppercase:before {
  content: "\e66a";
}

.ti-upload:before {
  content: "\e66b";
}

.ti-underline:before {
  content: "\e66c";
}

.ti-truck:before {
  content: "\e66d";
}

.ti-timer:before {
  content: "\e66e";
}

.ti-ticket:before {
  content: "\e66f";
}

.ti-thumb-up:before {
  content: "\e670";
}

.ti-thumb-down:before {
  content: "\e671";
}

.ti-text:before {
  content: "\e672";
}

.ti-stats-up:before {
  content: "\e673";
}

.ti-stats-down:before {
  content: "\e674";
}

.ti-split-v:before {
  content: "\e675";
}

.ti-split-h:before {
  content: "\e676";
}

.ti-smallcap:before {
  content: "\e677";
}

.ti-shine:before {
  content: "\e678";
}

.ti-shift-right:before {
  content: "\e679";
}

.ti-shift-left:before {
  content: "\e67a";
}

.ti-shield:before {
  content: "\e67b";
}

.ti-notepad:before {
  content: "\e67c";
}

.ti-server:before {
  content: "\e67d";
}

.ti-quote-right:before {
  content: "\e67e";
}

.ti-quote-left:before {
  content: "\e67f";
}

.ti-pulse:before {
  content: "\e680";
}

.ti-printer:before {
  content: "\e681";
}

.ti-power-off:before {
  content: "\e682";
}

.ti-plug:before {
  content: "\e683";
}

.ti-pie-chart:before {
  content: "\e684";
}

.ti-paragraph:before {
  content: "\e685";
}

.ti-panel:before {
  content: "\e686";
}

.ti-package:before {
  content: "\e687";
}

.ti-music:before {
  content: "\e688";
}

.ti-music-alt:before {
  content: "\e689";
}

.ti-mouse:before {
  content: "\e68a";
}

.ti-mouse-alt:before {
  content: "\e68b";
}

.ti-money:before {
  content: "\e68c";
}

.ti-microphone:before {
  content: "\e68d";
}

.ti-menu:before {
  content: "\e68e";
}

.ti-menu-alt:before {
  content: "\e68f";
}

.ti-map:before {
  content: "\e690";
}

.ti-map-alt:before {
  content: "\e691";
}

.ti-loop:before {
  content: "\e692";
}

.ti-location-pin:before {
  content: "\e693";
}

.ti-list:before {
  content: "\e694";
}

.ti-light-bulb:before {
  content: "\e695";
}

.ti-Italic:before {
  content: "\e696";
}

.ti-info:before {
  content: "\e697";
}

.ti-infinite:before {
  content: "\e698";
}

.ti-id-badge:before {
  content: "\e699";
}

.ti-hummer:before {
  content: "\e69a";
}

.ti-home:before {
  content: "\e69b";
}

.ti-help:before {
  content: "\e69c";
}

.ti-headphone:before {
  content: "\e69d";
}

.ti-harddrives:before {
  content: "\e69e";
}

.ti-harddrive:before {
  content: "\e69f";
}

.ti-gift:before {
  content: "\e6a0";
}

.ti-game:before {
  content: "\e6a1";
}

.ti-filter:before {
  content: "\e6a2";
}

.ti-files:before {
  content: "\e6a3";
}

.ti-file:before {
  content: "\e6a4";
}

.ti-eraser:before {
  content: "\e6a5";
}

.ti-envelope:before {
  content: "\e6a6";
}

.ti-download:before {
  content: "\e6a7";
}

.ti-direction:before {
  content: "\e6a8";
}

.ti-direction-alt:before {
  content: "\e6a9";
}

.ti-dashboard:before {
  content: "\e6aa";
}

.ti-control-stop:before {
  content: "\e6ab";
}

.ti-control-shuffle:before {
  content: "\e6ac";
}

.ti-control-play:before {
  content: "\e6ad";
}

.ti-control-pause:before {
  content: "\e6ae";
}

.ti-control-forward:before {
  content: "\e6af";
}

.ti-control-backward:before {
  content: "\e6b0";
}

.ti-cloud:before {
  content: "\e6b1";
}

.ti-cloud-up:before {
  content: "\e6b2";
}

.ti-cloud-down:before {
  content: "\e6b3";
}

.ti-clipboard:before {
  content: "\e6b4";
}

.ti-car:before {
  content: "\e6b5";
}

.ti-calendar:before {
  content: "\e6b6";
}

.ti-book:before {
  content: "\e6b7";
}

.ti-bell:before {
  content: "\e6b8";
}

.ti-basketball:before {
  content: "\e6b9";
}

.ti-bar-chart:before {
  content: "\e6ba";
}

.ti-bar-chart-alt:before {
  content: "\e6bb";
}

.ti-back-right:before {
  content: "\e6bc";
}

.ti-back-left:before {
  content: "\e6bd";
}

.ti-arrows-corner:before {
  content: "\e6be";
}

.ti-archive:before {
  content: "\e6bf";
}

.ti-anchor:before {
  content: "\e6c0";
}

.ti-align-right:before {
  content: "\e6c1";
}

.ti-align-left:before {
  content: "\e6c2";
}

.ti-align-justify:before {
  content: "\e6c3";
}

.ti-align-center:before {
  content: "\e6c4";
}

.ti-alert:before {
  content: "\e6c5";
}

.ti-alarm-clock:before {
  content: "\e6c6";
}

.ti-agenda:before {
  content: "\e6c7";
}

.ti-write:before {
  content: "\e6c8";
}

.ti-window:before {
  content: "\e6c9";
}

.ti-widgetized:before {
  content: "\e6ca";
}

.ti-widget:before {
  content: "\e6cb";
}

.ti-widget-alt:before {
  content: "\e6cc";
}

.ti-wallet:before {
  content: "\e6cd";
}

.ti-video-clapper:before {
  content: "\e6ce";
}

.ti-video-camera:before {
  content: "\e6cf";
}

.ti-vector:before {
  content: "\e6d0";
}

.ti-themify-logo:before {
  content: "\e6d1";
}

.ti-themify-favicon:before {
  content: "\e6d2";
}

.ti-themify-favicon-alt:before {
  content: "\e6d3";
}

.ti-support:before {
  content: "\e6d4";
}

.ti-stamp:before {
  content: "\e6d5";
}

.ti-split-v-alt:before {
  content: "\e6d6";
}

.ti-slice:before {
  content: "\e6d7";
}

.ti-shortcode:before {
  content: "\e6d8";
}

.ti-shift-right-alt:before {
  content: "\e6d9";
}

.ti-shift-left-alt:before {
  content: "\e6da";
}

.ti-ruler-alt-2:before {
  content: "\e6db";
}

.ti-receipt:before {
  content: "\e6dc";
}

.ti-pin2:before {
  content: "\e6dd";
}

.ti-pin-alt:before {
  content: "\e6de";
}

.ti-pencil-alt2:before {
  content: "\e6df";
}

.ti-palette:before {
  content: "\e6e0";
}

.ti-more:before {
  content: "\e6e1";
}

.ti-more-alt:before {
  content: "\e6e2";
}

.ti-microphone-alt:before {
  content: "\e6e3";
}

.ti-magnet:before {
  content: "\e6e4";
}

.ti-line-double:before {
  content: "\e6e5";
}

.ti-line-dotted:before {
  content: "\e6e6";
}

.ti-line-dashed:before {
  content: "\e6e7";
}

.ti-layout-width-full:before {
  content: "\e6e8";
}

.ti-layout-width-default:before {
  content: "\e6e9";
}

.ti-layout-width-default-alt:before {
  content: "\e6ea";
}

.ti-layout-tab:before {
  content: "\e6eb";
}

.ti-layout-tab-window:before {
  content: "\e6ec";
}

.ti-layout-tab-v:before {
  content: "\e6ed";
}

.ti-layout-tab-min:before {
  content: "\e6ee";
}

.ti-layout-slider:before {
  content: "\e6ef";
}

.ti-layout-slider-alt:before {
  content: "\e6f0";
}

.ti-layout-sidebar-right:before {
  content: "\e6f1";
}

.ti-layout-sidebar-none:before {
  content: "\e6f2";
}

.ti-layout-sidebar-left:before {
  content: "\e6f3";
}

.ti-layout-placeholder:before {
  content: "\e6f4";
}

.ti-layout-menu:before {
  content: "\e6f5";
}

.ti-layout-menu-v:before {
  content: "\e6f6";
}

.ti-layout-menu-separated:before {
  content: "\e6f7";
}

.ti-layout-menu-full:before {
  content: "\e6f8";
}

.ti-layout-media-right-alt:before {
  content: "\e6f9";
}

.ti-layout-media-right:before {
  content: "\e6fa";
}

.ti-layout-media-overlay:before {
  content: "\e6fb";
}

.ti-layout-media-overlay-alt:before {
  content: "\e6fc";
}

.ti-layout-media-overlay-alt-2:before {
  content: "\e6fd";
}

.ti-layout-media-left-alt:before {
  content: "\e6fe";
}

.ti-layout-media-left:before {
  content: "\e6ff";
}

.ti-layout-media-center-alt:before {
  content: "\e700";
}

.ti-layout-media-center:before {
  content: "\e701";
}

.ti-layout-list-thumb:before {
  content: "\e702";
}

.ti-layout-list-thumb-alt:before {
  content: "\e703";
}

.ti-layout-list-post:before {
  content: "\e704";
}

.ti-layout-list-large-image:before {
  content: "\e705";
}

.ti-layout-line-solid:before {
  content: "\e706";
}

.ti-layout-grid4:before {
  content: "\e707";
}

.ti-layout-grid3:before {
  content: "\e708";
}

.ti-layout-grid2:before {
  content: "\e709";
}

.ti-layout-grid2-thumb:before {
  content: "\e70a";
}

.ti-layout-cta-right:before {
  content: "\e70b";
}

.ti-layout-cta-left:before {
  content: "\e70c";
}

.ti-layout-cta-center:before {
  content: "\e70d";
}

.ti-layout-cta-btn-right:before {
  content: "\e70e";
}

.ti-layout-cta-btn-left:before {
  content: "\e70f";
}

.ti-layout-column4:before {
  content: "\e710";
}

.ti-layout-column3:before {
  content: "\e711";
}

.ti-layout-column2:before {
  content: "\e712";
}

.ti-layout-accordion-separated:before {
  content: "\e713";
}

.ti-layout-accordion-merged:before {
  content: "\e714";
}

.ti-layout-accordion-list:before {
  content: "\e715";
}

.ti-ink-pen:before {
  content: "\e716";
}

.ti-info-alt:before {
  content: "\e717";
}

.ti-help-alt:before {
  content: "\e718";
}

.ti-headphone-alt:before {
  content: "\e719";
}

.ti-hand-point-up:before {
  content: "\e71a";
}

.ti-hand-point-right:before {
  content: "\e71b";
}

.ti-hand-point-left:before {
  content: "\e71c";
}

.ti-hand-point-down:before {
  content: "\e71d";
}

.ti-gallery:before {
  content: "\e71e";
}

.ti-face-smile:before {
  content: "\e71f";
}

.ti-face-sad:before {
  content: "\e720";
}

.ti-credit-card:before {
  content: "\e721";
}

.ti-control-skip-forward:before {
  content: "\e722";
}

.ti-control-skip-backward:before {
  content: "\e723";
}

.ti-control-record:before {
  content: "\e724";
}

.ti-control-eject:before {
  content: "\e725";
}

.ti-comments-smiley:before {
  content: "\e726";
}

.ti-brush-alt:before {
  content: "\e727";
}

.ti-youtube:before {
  content: "\e728";
}

.ti-vimeo:before {
  content: "\e729";
}

.ti-twitter:before {
  content: "\e72a";
}

.ti-time:before {
  content: "\e72b";
}

.ti-tumblr:before {
  content: "\e72c";
}

.ti-skype:before {
  content: "\e72d";
}

.ti-share:before {
  content: "\e72e";
}

.ti-share-alt:before {
  content: "\e72f";
}

.ti-rocket:before {
  content: "\e730";
}

.ti-pinterest:before {
  content: "\e731";
}

.ti-new-window:before {
  content: "\e732";
}

.ti-microsoft:before {
  content: "\e733";
}

.ti-list-ol:before {
  content: "\e734";
}

.ti-linkedin:before {
  content: "\e735";
}

.ti-layout-sidebar-2:before {
  content: "\e736";
}

.ti-layout-grid4-alt:before {
  content: "\e737";
}

.ti-layout-grid3-alt:before {
  content: "\e738";
}

.ti-layout-grid2-alt:before {
  content: "\e739";
}

.ti-layout-column4-alt:before {
  content: "\e73a";
}

.ti-layout-column3-alt:before {
  content: "\e73b";
}

.ti-layout-column2-alt:before {
  content: "\e73c";
}

.ti-instagram:before {
  content: "\e73d";
}

.ti-google:before {
  content: "\e73e";
}

.ti-github:before {
  content: "\e73f";
}

.ti-flickr:before {
  content: "\e740";
}

.ti-facebook:before {
  content: "\e741";
}

.ti-dropbox:before {
  content: "\e742";
}

.ti-dribbble:before {
  content: "\e743";
}

.ti-apple:before {
  content: "\e744";
}

.ti-android:before {
  content: "\e745";
}

.ti-save:before {
  content: "\e746";
}

.ti-save-alt:before {
  content: "\e747";
}

.ti-yahoo:before {
  content: "\e748";
}

.ti-wordpress:before {
  content: "\e749";
}

.ti-vimeo-alt:before {
  content: "\e74a";
}

.ti-twitter-alt:before {
  content: "\e74b";
}

.ti-tumblr-alt:before {
  content: "\e74c";
}

.ti-trello:before {
  content: "\e74d";
}

.ti-stack-overflow:before {
  content: "\e74e";
}

.ti-soundcloud:before {
  content: "\e74f";
}

.ti-sharethis:before {
  content: "\e750";
}

.ti-sharethis-alt:before {
  content: "\e751";
}

.ti-reddit:before {
  content: "\e752";
}

.ti-pinterest-alt:before {
  content: "\e753";
}

.ti-microsoft-alt:before {
  content: "\e754";
}

.ti-linux:before {
  content: "\e755";
}

.ti-jsfiddle:before {
  content: "\e756";
}

.ti-joomla:before {
  content: "\e757";
}

.ti-html5:before {
  content: "\e758";
}

.ti-flickr-alt:before {
  content: "\e759";
}

.ti-email:before {
  content: "\e75a";
}

.ti-drupal:before {
  content: "\e75b";
}

.ti-dropbox-alt:before {
  content: "\e75c";
}

.ti-css3:before {
  content: "\e75d";
}

.ti-rss:before {
  content: "\e75e";
}

.ti-rss-alt:before {
  content: "\e75f";
}

@font-face {
  font-family: "jost";
  src: url("./../fonts/oblecmimiska-cz/jost-400-book-webfont.woff2") format("woff2"), url("./../fonts/oblecmimiska-cz/jost-400-book-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "jost";
  src: url("./../fonts/oblecmimiska-cz/jost-500-medium-webfont.woff2") format("woff2"), url("./../fonts/oblecmimiska-cz/jost-500-medium-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "jost";
  src: url("./../fonts/oblecmimiska-cz/jost-700-bold-webfont.woff2") format("woff2"), url("./../fonts/oblecmimiska-cz/jost-700-bold-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
@keyframes animationSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animationShake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

ul,
ol {
  padding: 0;
}

img {
  max-width: 100%;
  border-style: none;
}

.smap img {
  max-width: none !important;
}

input,
button,
textarea,
select {
  font: inherit;
}

a {
  background-color: transparent;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.dn {
  display: none;
}

.content-width {
  width: 1360px;
  margin: 0 auto;
}
@media (max-width: 1360px) {
  .content-width {
    width: 100%;
    padding: 0 20px;
  }
}

html, body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 30px;
}

a {
  color: #000000;
  text-decoration: underline;
}
a:hover {
  color: #000000;
}

.news-carousel {
  box-shadow: 0px 16px 24px 0px rgba(57, 46, 122, 0.33);
  background-color: #ebeeee;
}
@media (max-width: 1000px) {
  .news-carousel .splide__arrow--next {
    right: 0;
  }
  .news-carousel .splide__arrow--prev {
    left: 0;
  }
}
.news-carousel .splide__pagination {
  position: absolute;
  top: 20%;
  bottom: auto;
}
@media (max-width: 1000px) {
  .news-carousel .splide__pagination {
    top: auto;
    bottom: 3%;
  }
}
.news-carousel .splide__pagination__page {
  background: #ffffff;
}
@media (max-width: 1000px) {
  .news-carousel .splide__pagination__page {
    background: #F24C07;
  }
}
.news-carousel .splide__pagination__page.is-active {
  transform: scale(1.8);
}
@media (max-width: 1000px) {
  .news-carousel .splide__pagination__page.is-active {
    background: #F24C07;
  }
}
.news-carousel .splide__arrows .splide__arrow {
  background: transparent;
  top: 12%;
  transform: translateY(-12%);
}
@media (max-width: 1000px) {
  .news-carousel .splide__arrows .splide__arrow {
    top: 50%;
    transform: translateY(-50%);
  }
}
.news-carousel .splide__arrows .splide__arrow svg {
  width: 30px;
  height: 30px;
  fill: #5543c1;
}
.news-carousel__item {
  color: #5543c1;
  min-height: 458px;
}
@media (max-width: 1000px) {
  .news-carousel__item--image {
    text-align: center;
  }
}
.news-carousel__item--image image {
  display: block;
  max-width: 100%;
}
@media (max-width: 1000px) {
  .news-carousel__item--image image {
    margin: 0 auto;
  }
}
.news-carousel__item--headline {
  padding: 30px 40px 0 40px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  line-height: 38px;
}
.news-carousel__item--date {
  padding: 0 40px 15px 40px;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
}
.news-carousel__item--description {
  padding: 0 40px 20px 40px;
  font-size: 16px;
  line-height: 30px;
  line-height: 150%;
}
.news-carousel__item--button {
  padding: 20px 40px 40px 40px;
}
.news-carousel__item--button a {
  background-color: #F24C07;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  border-radius: 20px;
  padding: 11px 0;
  transition: 300ms;
}
.news-carousel__item--button a span {
  transition: 300ms;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 4px;
  background-color: #5543c1;
}
.news-carousel__item--button a:hover {
  background-color: #5543c1;
}
.news-carousel__item--button a:hover span {
  background-color: #F24C07;
}

/*!
 * baguetteBox.js
 * @author  feimosi
 * @version %%INJECT_VERSION%%
 * @url https://github.com/feimosi/baguetteBox.js
 */
#baguetteBox-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #222;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 0.5s ease;
}
#baguetteBox-overlay.visible {
  opacity: 1;
}
#baguetteBox-overlay .full-image {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}
#baguetteBox-overlay .full-image figure {
  display: inline;
  margin: 0;
  height: 100%;
}
#baguetteBox-overlay .full-image img {
  display: inline-block;
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
#baguetteBox-overlay .full-image figcaption {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  line-height: 1.8;
  white-space: normal;
  color: #ccc;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
  font-family: sans-serif;
}
#baguetteBox-overlay .full-image:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

#baguetteBox-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  transition: left 0.4s ease, transform 0.4s ease;
}
#baguetteBox-slider.bounce-from-right {
  animation: bounceFromRight 0.4s ease-out;
}
#baguetteBox-slider.bounce-from-left {
  animation: bounceFromLeft 0.4s ease-out;
}

@keyframes bounceFromRight {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -30px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes bounceFromLeft {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 30px;
  }
  100% {
    margin-left: 0;
  }
}
.baguetteBox-button#previous-button, .baguetteBox-button#next-button {
  top: 50%;
  top: calc(50% - 30px);
  width: 44px;
  height: 60px;
}

.baguetteBox-button {
  position: absolute;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 15%;
  background-color: #323232;
  background-color: rgba(50, 50, 50, 0.5);
  color: #ddd;
  font: 1.6em sans-serif;
  transition: background-color 0.4s ease;
}
.baguetteBox-button:focus, .baguetteBox-button:hover {
  background-color: rgba(50, 50, 50, 0.9);
}
.baguetteBox-button#next-button {
  right: 2%;
}
.baguetteBox-button#previous-button {
  left: 2%;
}
.baguetteBox-button#close-button {
  top: 20px;
  right: 2%;
  right: calc(2% + 6px);
  width: 30px;
  height: 30px;
}
.baguetteBox-button svg {
  position: absolute;
  left: 0;
  top: 0;
}

/*
    Preloader
    Borrowed from http://tobiasahlin.com/spinkit/
*/
.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2s infinite ease-in-out;
}

.baguetteBox-double-bounce2 {
  animation-delay: -1s;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
/*!
 * splite 2.4.10
 */
@keyframes splide-loading {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.splide__container {
  position: relative;
  box-sizing: border-box;
}

.splide__list {
  margin: 0 !important;
  padding: 0 !important;
  width: max-content;
  will-change: transform;
}
.splide.is-active .splide__list {
  display: flex;
}

.splide__pagination {
  display: inline-flex;
  align-items: center;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}
.splide__pagination li {
  list-style-type: none;
  display: inline-block;
  line-height: 1;
  margin: 0;
}
.splide {
  position: relative;
  visibility: hidden;
  outline: none;
}

.splide__slide {
  position: relative;
  box-sizing: border-box;
  list-style-type: none !important;
  margin: 0;
  outline: none;
  flex-shrink: 0;
}
.splide__slide img {
  vertical-align: bottom;
}

.splide__slider {
  position: relative;
}

.splide__spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #999;
  border-left-color: transparent;
  animation: splide-loading 1s infinite linear;
}

.splide__track {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.splide--draggable > .splide__track > .splide__list > .splide__slide {
  user-select: none;
}

.splide--fade > .splide__track > .splide__list {
  display: block;
}
.splide--fade > .splide__track > .splide__list > .splide__slide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
}
.splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  position: relative;
  z-index: 1;
  opacity: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide--ttb > .splide__track > .splide__list {
  display: block;
}
.splide--ttb > .splide__pagination {
  width: auto;
}

.splide__arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 2em;
  height: 2em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  opacity: 0.7;
  background: #ccc;
}
.splide__arrow svg {
  width: 1.2em;
  height: 1.2em;
}
.splide__arrow:hover {
  cursor: pointer;
  opacity: 0.9;
}
.splide__arrow:focus {
  outline: none;
}
.splide__arrow--prev {
  left: 1em;
}
.splide__arrow--prev svg {
  transform: scaleX(-1);
}
.splide__arrow--next {
  right: 1em;
}

.splide__pagination {
  position: absolute;
  z-index: 1;
  bottom: 0.5em;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
}
.splide__pagination__page {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #ccc;
  border-radius: 50%;
  margin: 3px;
  padding: 0;
  transition: transfrom 0.2s linear;
  border: none;
  opacity: 0.7;
}
.splide__pagination__page.is-active {
  transform: scale(1.4);
  background: white;
}
.splide__pagination__page:hover {
  cursor: pointer;
  opacity: 0.9;
}
.splide__pagination__page:focus {
  outline: none;
}

.splide__progress__bar {
  width: 0;
  height: 3px;
  background: #ccc;
}

.splide--nav > .splide__track > .splide__list > .splide__slide {
  border: 3px solid transparent;
}
.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border-color: black;
}
.splide--nav > .splide__track > .splide__list > .splide__slide:focus {
  outline: none;
}

.splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev, .splide--rtl > .splide__arrows .splide__arrow--prev {
  right: 1em;
  left: auto;
}
.splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev svg, .splide--rtl > .splide__arrows .splide__arrow--prev svg {
  transform: scaleX(1);
}
.splide--rtl > .splide__track > .splide__arrows .splide__arrow--next, .splide--rtl > .splide__arrows .splide__arrow--next {
  left: 1em;
  right: auto;
}
.splide--rtl > .splide__track > .splide__arrows .splide__arrow--next svg, .splide--rtl > .splide__arrows .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide--ttb > .splide__track > .splide__arrows .splide__arrow, .splide--ttb > .splide__arrows .splide__arrow {
  left: 50%;
  transform: translate(-50%, 0);
}
.splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev, .splide--ttb > .splide__arrows .splide__arrow--prev {
  top: 1em;
}
.splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev svg, .splide--ttb > .splide__arrows .splide__arrow--prev svg {
  transform: rotateZ(-90deg);
}
.splide--ttb > .splide__track > .splide__arrows .splide__arrow--next, .splide--ttb > .splide__arrows .splide__arrow--next {
  top: auto;
  bottom: 1em;
}
.splide--ttb > .splide__track > .splide__arrows .splide__arrow--next svg, .splide--ttb > .splide__arrows .splide__arrow--next svg {
  transform: rotateZ(90deg);
}
.splide--ttb > .splide__pagination {
  display: flex;
  flex-direction: column;
  bottom: 50%;
  left: auto;
  right: 0.5em;
  transform: translate(0, 50%);
}

.base-button,
.base-button-transparent,
.base-button-action-colored {
  display: inline-block;
  border: 2px solid transparent;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  padding: 10px 30px;
  transition: 300ms;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
}

.base-button-action-colored {
  background: #ba455e;
  color: #ffffff;
  border-color: #ba455e;
}
.base-button-action-colored:hover {
  color: #ffffff;
  background: #AF0026;
  border-color: #AF0026;
}

.base-button {
  background: #1c1c1c;
  color: #ffffff;
  border-color: #1c1c1c;
}
.base-button:hover {
  color: #1c1c1c;
  background: transparent;
}

.base-button-transparent {
  color: #1c1c1c;
  background: transparent;
  border-color: #1c1c1c;
}
.base-button-transparent:hover {
  color: #ffffff;
  background: #1c1c1c;
}

.big-banner {
  width: 100%;
  position: relative;
  height: 45vw;
}
.big-banner__content {
  height: 100%;
  position: relative;
  z-index: 2;
}
@media (max-width: 550px) {
  .big-banner__content .big-banner__content--button {
    text-align: center;
  }
}
.big-banner__content .big-banner__content--button a {
  display: inline-block;
  color: #ffffff;
  background: #5543c1;
  text-align: center;
  border-radius: 20px;
  padding: 10px 50px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-decoration: none;
  font-size: 40px;
  line-height: 54px;
  position: relative;
}
.big-banner__content .big-banner__content--button a:hover {
  text-decoration: none;
}
@media (max-width: 730px) {
  .big-banner__content .big-banner__content--button a {
    padding: 10px 20px;
  }
}
.big-banner__content .big-banner__content--button a:after {
  display: block;
  width: 61px;
  height: 45px;
  content: "";
  background-image: url("/assets/images/ico-arrow-down.png");
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: -70px;
  transform: translate(-50%, 0);
}
@media (max-width: 1000px) {
  .big-banner__content .big-banner__content--button a:after {
    width: 30px;
    height: 22px;
    background-size: cover;
    bottom: -40px;
  }
}
.big-banner__content .big-banner__content--button a:hover {
  text-decoration: none;
  background: #4d3bb4;
}
.big-banner__content .big-banner__content--button a:hover:after {
  animation: downUpAnimation 1s linear infinite;
}
@keyframes downUpAnimation {
  0%, 100% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-50%, 10px);
  }
}
@media (max-width: 1000px) {
  .big-banner__content .big-banner__content--button a {
    font-size: 20px;
    line-height: 34px;
  }
}
@media (max-width: 730px) {
  .big-banner__content .big-banner__content--button a {
    font-size: 16px;
    line-height: 30px;
  }
}
.big-banner__content .big-banner__content--text {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 70px;
  line-height: 84px;
  width: 600px;
  padding-bottom: 50px;
}
@media (max-width: 1000px) {
  .big-banner__content .big-banner__content--text {
    width: 350px;
    font-size: 40px;
    line-height: 54px;
    padding-bottom: 30px;
  }
}
@media (max-width: 730px) {
  .big-banner__content .big-banner__content--text {
    width: 200px;
    font-size: 25px;
    line-height: 39px;
    padding-bottom: 20px;
  }
}
@media (max-width: 550px) {
  .big-banner__content .big-banner__content--text {
    width: 100%;
    text-align: center;
  }
}
.big-banner__content .big-banner__content--position-container {
  position: absolute;
  bottom: 30%;
  right: 0;
}
@media (max-width: 730px) {
  .big-banner__content .big-banner__content--position-container {
    right: 10%;
  }
}
@media (max-width: 550px) {
  .big-banner__content .big-banner__content--position-container {
    width: 100%;
    right: 0;
  }
}
.big-banner__img-cover {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
}
.big-banner__img-cover img {
  object-fit: cover;
  object-position: center top;
  width: 100%;
  height: 100%;
}

body.modal-is-open {
  overflow: hidden;
}

.modal-window {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-window .modal-window__container {
  position: relative;
  width: auto;
  max-width: 700px;
  margin: 20px auto;
}
.modal-window .modal-window__container .modal-window__container-content {
  position: relative;
  background: #ffffff;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.modal-window .modal-window__container .modal-window__container-content .confirm-and-close-text {
  background: #ffffff;
  text-align: center;
  padding: 20px;
}
.modal-window .modal-window__container .modal-window__container-content .inner-loading {
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.modal-window .modal-window__container .modal-window__container-content .inner-loading > div {
  background: transparent;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}
.modal-window .modal-window__container .modal-window__container-headline {
  position: relative;
  background: #ba455e;
  text-transform: uppercase;
  font-weight: bold;
  color: #ffffff;
  padding: 15px 40px 15px 20px;
  display: none;
  font-size: 18px;
  line-height: 32px;
}
.modal-window .modal-window__container .modal-window__container-headline .modal-window__container-close {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  display: block;
  font-size: 35px;
}
.modal-window .modal-window__container .modal-window__container-headline .modal-window__container-close:hover {
  color: #d77a8e;
}

.carousel .carousel__item > a {
  position: relative;
  display: block;
}
.carousel .carousel__item > a .carousel__text-content {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.6);
}
.carousel .carousel__item > a .carousel__text-content .carousel__text-content--headline {
  display: block;
}
.carousel .carousel__item > a .carousel__text-content .carousel__text-content--text {
  display: block;
}
.carousel .carousel__item > a .carousel__text-content .carousel__text-content--button {
  display: inline-block;
}

.breadcrumb {
  padding-top: 10px;
}
@media (max-width: 1360px) {
  .breadcrumb {
    padding: 10px 10px 0 10px;
  }
}
.breadcrumb ol {
  display: flex;
  list-style: none;
  justify-content: center;
}
@media (max-width: 1360px) {
  .breadcrumb ol {
    flex-wrap: wrap;
  }
}
.breadcrumb ol li span {
  font-weight: bold;
}
.breadcrumb ol li a {
  text-decoration: underline;
}
.breadcrumb ol li a:hover {
  text-decoration: none;
}
.breadcrumb ol li i {
  font-size: 25px;
}
.breadcrumb ol li:before {
  display: inline-block;
  font-family: "themify";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e628";
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  top: 2px;
}
.breadcrumb ol li.breadcrumb__homepage:before {
  display: none;
}

header {
  background: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px 0;
  position: relative;
}
@media (max-width: 1360px) {
  header {
    position: relative;
    z-index: 4;
  }
}
header > div .header__inner {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
@media (min-width: 1000px) {
  header > div {
    position: relative;
  }
}
header .header__logo-container {
  display: flex;
  align-items: center;
}
header .header__content-container {
  display: flex;
  align-items: center;
}
@media (max-width: 950px) {
  header .header__content-container {
    justify-content: flex-end;
  }
}

.headline-content-banner {
  background: #f1f1f1;
  padding: 20px 0;
  margin: 0 0 40px 0;
  text-align: center;
}
.headline-content-banner h1 {
  font-size: 40px;
  line-height: 54px;
}
.headline-content-banner h1 span {
  display: block;
  font-size: 18px;
  line-height: 32px;
  font-weight: normal;
}

@media (max-width: 1360px) {
  .header__logo {
    width: 55%;
  }
}
.header__logo a {
  font-size: 50px;
  font-weight: 300;
  height: 100px;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
}
@media (max-width: 1360px) {
  .header__logo a {
    font-size: 20px;
    height: 50px;
    justify-content: center;
  }
  .header__logo a img {
    max-height: 100%;
  }
}
.header__logo a span {
  padding-left: 10px;
  position: relative;
  top: 5px;
}

.logo-container {
  font-family: "Roboto", sans-serif;
}
.logo-container .logo-container__link {
  text-decoration: none;
  color: #5543c1;
  display: flex;
}
.logo-container .logo-container__link:hover {
  text-decoration: none !important;
}
.logo-container .logo-container__text {
  color: #5543c1;
  text-transform: uppercase;
  padding-top: 20px;
  font-weight: bold;
  font-size: 20px;
}
.logo-container .logo-container__image img {
  height: 70px;
  width: auto;
}
.logo-container.header .logo-container__link,
.logo-container.header .logo-container__text {
  color: #ffffff;
}
.logo-container.header .logo-container__text {
  padding-top: 15px;
  font-size: 17px;
}
.logo-container.header .logo-container__image {
  display: flex;
}
.logo-container.header .logo-container__image img {
  height: 80px;
}

.main-menu {
  display: flex;
  align-items: stretch;
  height: 100%;
}
@media (max-width: 1000px) {
  .main-menu {
    display: none;
  }
}
@media (max-width: 1000px) {
  .main-menu.open-mobile-menu {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #ffffff;
    border-bottom: 4px solid #5cb0a5;
    height: auto;
    box-shadow: rgba(33, 35, 38, 0.9) 0px 10px 10px -10px;
  }
}
.main-menu__search-button {
  display: flex;
  align-items: center;
}
@media (max-width: 1000px) {
  .main-menu__search-button {
    text-align: center;
    justify-content: center;
    padding: 10px 0 !important;
  }
}
.main-menu__search-button.is-homepage {
  display: none;
}
.main-menu__search-button span {
  padding: 15px;
  background: #5cb0a5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: 300ms;
}
.main-menu__search-button span:hover {
  background: #4da095;
  transform: scale(1.2);
}
.main-menu__search-button span svg {
  width: 25px;
  height: 25px;
  color: #ffffff;
}
.main-menu__search-button, .main-menu__category, .main-menu__pages {
  padding: 0 10px;
}
.main-menu__search-button a, .main-menu__category a, .main-menu__pages a {
  text-decoration: none;
  display: block;
  padding: 10px 20px;
  font-size: 20px;
  line-height: 34px;
  transition: 300ms;
}
.main-menu__category {
  display: flex;
  align-items: stretch;
  border-right: 1px solid #5cb0a5;
}
@media (max-width: 1000px) {
  .main-menu__category a {
    text-align: center;
    background: #5cb0a5;
    border-radius: 5px;
    color: #ffffff;
  }
}
.main-menu__category a:hover {
  background: #5cb0a5;
  border-radius: 5px;
  color: #ffffff;
}
.main-menu__category--icon svg {
  width: 50px;
  height: 50px;
  color: #5cb0a5;
}
.main-menu__category--icon, .main-menu__category--list {
  display: flex;
  align-items: center;
}
@media (max-width: 1000px) {
  .main-menu__category--list {
    width: 100%;
  }
}
.main-menu__category--list ul {
  list-style: none;
  display: flex;
  align-items: center;
}
@media (max-width: 1000px) {
  .main-menu__category--list ul {
    width: 100%;
    flex-wrap: wrap;
  }
}
.main-menu__category--list ul li {
  padding: 0 10px;
}
@media (max-width: 1000px) {
  .main-menu__category--list ul li {
    width: 50%;
    padding: 10px;
  }
}
.main-menu__pages {
  display: flex;
  align-items: center;
}
@media (max-width: 1000px) {
  .main-menu__pages {
    display: block;
  }
}
@media (max-width: 1000px) {
  .main-menu__pages a {
    text-align: center;
  }
}
.main-menu__pages a:hover {
  border-bottom: 5px solid #5cb0a5;
}
.main-menu__pages ul {
  list-style: none;
  display: flex;
}
@media (max-width: 1000px) {
  .main-menu__pages ul {
    display: block;
  }
  .main-menu__pages ul li {
    border-top: 1px solid #5cb0a5;
  }
}

.mobile-menu-button {
  width: 50px;
  height: 40px;
  text-align: center;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5cb0a5;
  margin-left: 20px;
}
@media (min-width: 1000px) {
  .mobile-menu-button {
    display: none;
  }
}
.mobile-menu-button i {
  display: block;
  font-size: 30px;
  margin-top: 3px;
  color: #ffffff;
}
.mobile-menu-button i:before {
  display: inline-block;
  font-family: "Pe-icon-7-stroke";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e636";
}
.mobile-menu-button.is-open i:before {
  display: inline-block;
  font-family: "Pe-icon-7-stroke";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e680";
}

.search-form--close {
  text-align: center;
  display: none;
  padding-top: 30px;
}
.search-form--close svg {
  width: 40px;
  height: 40px;
  color: #1e1e1e;
  cursor: pointer;
  transition: 300ms;
}
.search-form--close svg:hover {
  color: #323232;
  transform: scale(1.2);
}

.search-overload {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background: rgba(255, 255, 255, 0.8);
  display: none;
}
.search-overload.show {
  display: block;
}

.search-form {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 50%;
  border-radius: 25px;
  background: #5cb0a5;
  margin: 0 auto;
  padding: 15px 0;
}
@media (max-width: 550px) {
  .search-form {
    display: none;
    padding: 10px 5px;
    width: 95%;
  }
}
.search-form form {
  text-align: center;
}
.search-form form button {
  border: none;
  background: transparent;
  cursor: pointer;
  padding-left: 10px;
}
.search-form form button svg {
  width: 27px;
  height: 27px;
  color: #ffffff;
  position: relative;
  top: 3px;
  left: -3px;
}
.search-form form input {
  background: transparent;
  border: none;
  font-size: 25px;
  color: #ffffff;
  width: 88%;
}
@media (max-width: 950px) {
  .search-form form input {
    width: 100px;
  }
}
@media (max-width: 730px) {
  .search-form form input {
    width: 200px;
  }
}
@media (max-width: 550px) {
  .search-form form input {
    width: 75%;
  }
}
.search-form form input:focus {
  outline: none;
}
.search-form form input::placeholder {
  color: #ffffff;
}

.search-container {
  padding: 40px 0;
}
@media (max-width: 550px) {
  .search-container {
    padding: 0;
  }
}
.search-container.hide {
  display: none;
}
.search-container.open {
  width: 100%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 6;
}
@media (max-width: 550px) {
  .search-container.open .search-form {
    display: block;
  }
}
.search-container.open .search-form--close {
  display: block;
}

#myProgress {
  width: 100%;
  background-color: #c2c2c2;
  cursor: pointer;
  border-radius: 10px;
}

#myBar {
  width: 0;
  height: 10px;
  background-color: #5cb0a5;
  border-radius: 10px;
}

.logo {
  display: none;
}

.btn-action {
  cursor: pointer;
  padding-top: 10px;
  width: 30px;
}

.btn-ctn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.infos-ctn {
  text-align: center;
}

.btn-ctn > div {
  padding: 15px 10px;
}

.infos-ctn > div {
  margin-bottom: 8px;
  color: #5cb0a5;
}

.first-btn {
  margin-left: 3px;
}

.duration {
  margin-left: 10px;
  display: none;
}

.timer {
  font-size: 16px;
  line-height: 30px;
}

.title {
  text-align: center;
  font-size: 30px;
  line-height: 44px;
}

.player-ctn {
  border-radius: 5px;
  width: 420px;
  padding: 10px;
  background-color: #ffffff;
  border: 4px solid #5cb0a5;
  margin: 0;
}
@media (max-width: 1000px) {
  .player-ctn {
    width: 100%;
  }
}

.playlist-track-ctn {
  display: flex;
  background-color: transparent;
  border: 1px solid #5cb0a5;
  margin-top: 3px;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  /*border: 1px solid #ffc266; */
}
.playlist-track-ctn > div {
  margin: 5px 10px;
}

.playlist-info-track {
  width: 80%;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #5cb0a5;
  font-size: 14px;
  pointer-events: none;
}

.playlist-duration {
  padding-top: 7px;
  padding-bottom: 7px;
  color: #5cb0a5;
  font-size: 14px;
  pointer-events: none;
}

.playlist-ctn {
  padding-bottom: 20px;
}
@media (min-width: 730px) {
  .playlist-ctn {
    max-height: 500px;
    overflow-y: auto;
  }
}

.active-track {
  background: #5cb0a5;
  color: #ffffff !important;
  font-weight: bold;
}
.active-track .playlist-btn-play i,
.active-track .playlist-download i {
  color: #ffffff;
}
.active-track > .playlist-info-track, .active-track > .playlist-duration, .active-track > .playlist-btn-play, .active-track > .playlist-download {
  color: #ffffff !important;
}

.playlist-btn-play,
.playlist-download {
  pointer-events: none;
  padding-top: 5px;
  padding-bottom: 5px;
}

.fas {
  color: #5cb0a5;
  font-size: 20px;
}

.audio-catalog-detail {
  display: flex;
}
@media (max-width: 1000px) {
  .audio-catalog-detail {
    flex-wrap: wrap;
  }
}
@media (max-width: 1360px) {
  .audio-catalog-detail__info {
    flex-grow: 1;
    width: 50%;
  }
}
.audio-catalog-detail__info--image {
  padding-bottom: 30px;
}
.audio-catalog-detail__info--image img {
  display: block;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
@media (max-width: 1000px) {
  .audio-catalog-detail__info--image img {
    margin: 0 auto;
  }
}
.audio-catalog-detail__description {
  width: 100%;
  padding-top: 30px;
  order: 3;
}
.audio-catalog-detail__player {
  padding-right: 50px;
}
.audio-catalog-detail__player--download-all {
  padding-top: 30px;
  text-align: center;
}
@media (max-width: 1000px) {
  .audio-catalog-detail__player {
    padding-bottom: 20px;
    padding-right: 0;
    width: 100%;
  }
}
@media (max-width: 1000px) {
  .audio-catalog-detail__info {
    order: 1;
  }
  .audio-catalog-detail__player {
    order: 2;
    padding-top: 20px;
  }
}

.audio-catalog-detail-parameters.links {
  display: flex;
  align-items: center;
}
.audio-catalog-detail-parameters__name {
  font-weight: bold;
  display: inline-block;
  width: 130px;
}

.audio-catalog-detail-link {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  align-items: center;
}
@media (max-width: 1000px) {
  .audio-catalog-detail-link {
    justify-content: center;
  }
}
.audio-catalog-detail-link__item {
  padding-right: 20px;
}
.audio-catalog-detail-link__item a {
  display: inline-block;
  transition: 700ms;
}
.audio-catalog-detail-link__item a i, .audio-catalog-detail-link__item a img {
  transition: 700ms;
}
.audio-catalog-detail-link__item a:hover {
  transform: scale(1.1);
}
.audio-catalog-detail-link__item a:hover i, .audio-catalog-detail-link__item a:hover img {
  transform: scale(1.1);
}
.audio-catalog-detail-link__item i {
  font-size: 30px;
  color: #000000;
}
.audio-catalog-detail-link__item img {
  display: block;
  height: 40px;
  line-height: 40px;
}

.audio-book-list {
  display: flex;
  flex-wrap: wrap;
}

.audio-book-item {
  margin: 0.5%;
  width: 24%;
  padding: 10px;
  text-align: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: 300ms;
}
@media (max-width: 1000px) {
  .audio-book-item {
    width: 49%;
  }
}
@media (max-width: 550px) {
  .audio-book-item {
    width: 100%;
  }
}
.audio-book-item:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: scale(1.01);
}
.audio-book-item a {
  display: block;
  text-decoration: none;
}
.audio-book-item .audio-book-item__image,
.audio-book-item .audio-book-item__name {
  display: block;
}
.audio-book-item .audio-book-item__name {
  background: #5cb0a5;
  padding: 15px 10px;
  color: #ffffff;
  font-size: 20px;
  line-height: 34px;
}
.audio-book-item .audio-book-item__image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.gallery-list {
  display: flex;
  padding: 30px 0;
}
.gallery-list .gallery-item {
  padding: 10px;
}
.gallery-list .gallery-item img {
  box-shadow: 0px 16px 24px 0px rgba(57, 46, 122, 0.33);
}
.gallery-list .gallery-item:first-of-type {
  padding-left: 0;
}

form .form-item {
  padding: 5px 0;
}
form .form-item.required label {
  font-weight: 900;
}
form .form-item.required label:after {
  content: "*";
}
form .form-item label {
  color: #555555;
  display: block;
}
form .form-item input, form .form-item select, form .form-item textarea {
  color: #555555;
  border: 1px solid #d4d4d4;
  padding: 5px 15px;
}
form .form-item select {
  padding-top: 15px;
  padding-bottom: 15px;
}
form .form-item.error label {
  color: #c62447;
}
form .form-item.error input, form .form-item.error select, form .form-item.error textarea {
  border-color: #c62447;
}
form .form-item .error-message {
  color: #c62447;
}

.form-list-of-links {
  display: inline-block;
  position: relative;
  min-height: 16px;
}
.form-list-of-links .form-list-of-links__list {
  border: 1px solid #000000;
  display: block;
}
.form-list-of-links .form-list-of-links__list a {
  display: none;
}
.form-list-of-links .form-list-of-links__list a.active {
  display: block;
}
.form-list-of-links:hover .form-list-of-links__list {
  position: absolute;
  z-index: 2;
  background: #ffffff;
  left: 0;
  top: 0;
  white-space: nowrap;
}
.form-list-of-links:hover .form-list-of-links__list a {
  display: block;
}

/** Light color-scheme **/
:root {
  --cc-bg: #fff;
  --cc-text: #5cb0a5;
  --cc-border-radius: .35em;
  --cc-btn-primary-bg: #5cb0a5;
  --cc-btn-primary-text: var(--cc-bg);
  --cc-btn-primary-hover-bg: #33615b;
  --cc-btn-secondary-bg: #eaeff2;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #d8e0e6;
  --cc-btn-border-radius: 4px;
  --cc-toggle-bg-off: #919ea6;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #d5dee2;
  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #ecf2fa;
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: #f0f4f7;
  --cc-cookie-category-block-bg-hover: #e9eff4;
  --cc-section-border: #f1f3f5;
  --cc-cookie-table-border: #e9edf2;
  --cc-overlay-bg: rgba(4, 6, 8, .85);
  --cc-webkit-scrollbar-bg: #cfd5db;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}

/** Dark color-scheme **/
.c_darkmode {
  --cc-bg: #181b1d;
  --cc-text: #d8e5ea;
  --cc-btn-primary-bg: #a6c4dd;
  --cc-btn-primary-text: #000;
  --cc-btn-primary-hover-bg: #c2dff7;
  --cc-btn-secondary-bg: #33383c;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #3e454a;
  --cc-toggle-bg-off: #667481;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #454c54;
  --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
  --cc-toggle-knob-icon-color: var(--cc-bg);
  --cc-block-text: #b3bfc5;
  --cc-cookie-category-block-bg: #23272a;
  --cc-cookie-category-block-bg-hover: #2b3035;
  --cc-section-border: #292d31;
  --cc-cookie-table-border: #2b3035;
  --cc-webkit-scrollbar-bg: #667481;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}

.cc_div *,
.cc_div *:hover,
.cc_div :before,
.cc_div :after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: none;
  font-style: inherit;
  font-variant: normal;
  font-weight: inherit;
  font-family: inherit;
  line-height: 1.2;
  font-size: 1em;
  transition: none;
  animation: none;
  margin: 0;
  padding: 0;
  text-transform: none;
  letter-spacing: unset;
  color: inherit;
  background: none;
  border: none;
  border-radius: unset;
  box-shadow: none;
  text-decoration: none;
  text-align: left;
  visibility: unset;
  height: auto;
  vertical-align: baseline;
}

.cc_div {
  font-size: 16px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: var(--cc-text);
}

.cc_div .c-bn,
.cc_div .b-tl,
#s-ttl,
#c-ttl,
#s-bl td:before {
  font-weight: 600;
}

#cm,
#s-inr,
.cc_div .c-bl,
.cc_div .b-tl,
#s-bl .act .b-acc {
  border-radius: var(--cc-border-radius);
}

#s-bl .act .b-acc {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.cc_div input,
.cc_div button,
.cc_div a {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  overflow: hidden;
}

.cc_div a {
  border-bottom: 1px solid;
}

.cc_div a:hover {
  text-decoration: none;
  border-color: transparent;
}

/* Make elements "animatable" */
.c--anim #cm,
.c--anim #s-cnt,
.c--anim #s-inr,
#cs-ov,
#cm-ov {
  transition: visibility 0.25s linear, opacity 0.25s ease, transform 0.25s ease !important;
}

.c--anim .c-bn {
  transition: background-color 0.25s ease !important;
}

/* start transitions */
.c--anim #cm.bar.slide,
.c--anim .bar.slide #s-inr {
  transition: visibility 0.4s ease, opacity 0.4s ease, transform 0.4s ease !important;
}

.c--anim #cm.bar.slide + #cm-ov,
.c--anim .bar.slide + #cs-ov {
  transition: visibility 0.4s ease, opacity 0.4s ease, transform 0.4s ease !important;
}

#cm.bar.slide,
.cc_div .bar.slide #s-inr {
  transform: translateX(100%);
  opacity: 1;
}

#cm.bar.top.slide,
.cc_div .bar.left.slide #s-inr {
  transform: translateX(-100%);
  opacity: 1;
}

#cm.slide,
.cc_div .slide #s-inr {
  transform: translateY(1.6em);
}

#cm.top.slide {
  transform: translateY(-1.6em);
}

#cm.bar.slide {
  transform: translateY(100%);
}

#cm.bar.top.slide {
  transform: translateY(-100%);
}

/* end transitions */
/* Show modals */
.show--consent .c--anim #cm,
.show--consent .c--anim #cm.bar,
.show--settings .c--anim #s-inr,
.show--settings .c--anim .bar.slide #s-inr {
  opacity: 1;
  transform: scale(1);
  visibility: visible !important;
}

.show--consent .c--anim #cm.box.middle,
.show--consent .c--anim #cm.cloud.middle {
  transform: scale(1) translateY(-50%);
}

.show--settings .c--anim #s-cnt {
  visibility: visible !important;
}

/* Show overlays */
.force--consent.show--consent .c--anim #cm-ov,
.show--settings .c--anim #cs-ov {
  visibility: visible !important;
  opacity: 1 !important;
}

#cm {
  font-family: inherit;
  padding: 1.1em 1.5em 1.4em 1.5em;
  position: fixed;
  z-index: 1;
  background: var(--cc-bg);
  max-width: 24.2em;
  width: 100%;
  bottom: 1.25em;
  right: 1.25em;
  box-shadow: 0 0.625em 1.875em #000000;
  box-shadow: 0 0.625em 1.875em rgba(2, 2, 3, 0.28);
  opacity: 0;
  visibility: hidden;
  transform: scale(0.95);
  line-height: initial;
}

/** fix https://github.com/orestbida/cookieconsent/issues/94 **/
#cc_div #cm {
  display: block !important;
}

#c-ttl {
  margin-bottom: 0.7em;
  font-size: 1.05em;
}

.cloud #c-ttl {
  margin-top: -0.15em;
}

#c-txt {
  font-size: 0.9em;
  line-height: 1.5em;
}

.cc_div #c-bns {
  display: flex;
  justify-content: space-between;
  margin-top: 1.4em;
}

.cc_div .c-bn {
  color: var(--cc-btn-secondary-text);
  background: var(--cc-btn-secondary-bg);
  padding: 1em 1.7em;
  display: inline-block;
  cursor: pointer;
  font-size: 0.82em;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  text-align: center;
  border-radius: var(--cc-btn-border-radius);
  flex: 1;
}

#c-bns button + button,
#s-cnt button + button,
#s-c-bn {
  float: right;
  margin-left: 1em;
}

#s-cnt #s-rall-bn {
  float: none;
}

#cm .c_link:hover,
#cm .c_link:active,
#s-cnt button + button:hover,
#s-cnt button + button:active,
#s-c-bn:active,
#s-c-bn:hover {
  background: var(--cc-btn-secondary-hover-bg);
}

/**
CookieConsent settings modal
**/
#s-cnt {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  display: table;
  height: 100%;
  visibility: hidden;
}

#s-bl {
  outline: none;
}

#s-bl .title {
  margin-top: 1.4em;
}

#s-bl .title:first-child {
  margin-top: 0;
}

#s-bl .b-bn {
  margin-top: 0;
}

#s-bl .b-acc .p {
  margin-top: 0;
  padding: 1em;
}

#s-cnt .b-bn .b-tl {
  display: block;
  font-family: inherit;
  font-size: 0.95em;
  width: 100%;
  position: relative;
  padding: 1.3em 6.4em 1.3em 2.7em;
  background: none;
  transition: background-color 0.25s ease;
}

#s-cnt .b-bn .b-tl.exp {
  cursor: pointer;
}

#s-cnt .act .b-bn .b-tl {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

#s-cnt .b-bn .b-tl:active,
#s-cnt .b-bn .b-tl:hover {
  background: var(--cc-cookie-category-block-bg-hover);
}

#s-bl .b-bn {
  position: relative;
}

#s-bl .c-bl {
  padding: 1em;
  margin-bottom: 0.5em;
  border: 1px solid var(--cc-section-border);
  transition: background-color 0.25s ease;
}

#s-bl .c-bl:hover {
  background: var(--cc-cookie-category-block-bg);
}

#s-bl .c-bl:last-child {
  margin-bottom: 0.5em;
}

#s-bl .c-bl:first-child {
  transition: none;
  padding: 0;
  margin-top: 0;
  border: none;
  margin-bottom: 2em;
}

#s-bl .c-bl:not(.b-ex):first-child:hover {
  background: transparent;
  background: unset;
}

#s-bl .c-bl.b-ex {
  padding: 0;
  border: none;
  background: var(--cc-cookie-category-block-bg);
  transition: none;
}

#s-bl .c-bl.b-ex + .c-bl {
  margin-top: 2em;
}

#s-bl .c-bl.b-ex + .c-bl.b-ex {
  margin-top: 0;
}

#s-bl .c-bl.b-ex:first-child {
  margin-bottom: 1em;
}

#s-bl .c-bl.b-ex:first-child {
  margin-bottom: 0.5em;
}

#s-bl .b-acc {
  max-height: 0;
  overflow: hidden;
  padding-top: 0;
  margin-bottom: 0;
  display: none;
}

#s-bl .act .b-acc {
  max-height: 100%;
  display: block;
  overflow: hidden;
}

#s-cnt .p {
  font-size: 0.9em;
  line-height: 1.5em;
  margin-top: 0.85em;
  color: var(--cc-block-text);
}

.cc_div .b-tg .c-tgl:disabled {
  cursor: not-allowed;
}

#c-vln {
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

#cs {
  padding: 0 1.7em;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
}

#s-inr {
  height: 100%;
  position: relative;
  max-width: 45em;
  margin: 0 auto;
  transform: scale(0.96);
  opacity: 0;
  padding-top: 4.75em;
  padding-bottom: 4.75em;
  position: relative;
  height: 100%;
  overflow: hidden;
  visibility: hidden;
  box-shadow: rgba(3, 6, 9, 0.26) 0px 13px 27px -5px;
}

#s-inr,
#s-hdr,
#s-bns {
  background: var(--cc-bg);
}

#s-bl {
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
  height: 100%;
  padding: 1.3em 1.8em;
  display: block;
  width: 100%;
}

#s-bns {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em 1.8em;
  border-top: 1px solid var(--cc-section-border);
  height: 4.75em;
}

.cc_div .cc-link {
  color: var(--cc-btn-primary-bg);
  border-bottom: 1px solid var(--cc-btn-primary-bg);
  display: inline;
  padding-bottom: 0;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}

.cc_div .cc-link:hover,
.cc_div .cc-link:active {
  border-color: transparent;
}

#c-bns button:first-child,
#s-bns button:first-child {
  color: var(--cc-btn-primary-text);
  background: var(--cc-btn-primary-bg);
}

#c-bns.swap button:first-child {
  color: var(--cc-btn-secondary-text);
  background: var(--cc-btn-secondary-bg);
}

#c-bns.swap button:last-child {
  color: var(--cc-btn-primary-text);
  background: var(--cc-btn-primary-bg);
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg {
  background: var(--cc-toggle-bg-on);
}

#c-bns button:first-child:active,
#c-bns button:first-child:hover,
#s-bns button:first-child:active,
#s-bns button:first-child:hover,
#c-bns.swap button:last-child:active,
#c-bns.swap button:last-child:hover {
  background: var(--cc-btn-primary-hover-bg);
}

#c-bns.swap button:first-child:active,
#c-bns.swap button:first-child:hover {
  background: var(--cc-btn-secondary-hover-bg);
}

#s-hdr {
  position: absolute;
  top: 0;
  width: 100%;
  display: table;
  padding: 0 1.8em;
  height: 4.75em;
  vertical-align: middle;
  z-index: 2;
  border-bottom: 1px solid var(--cc-section-border);
}

#s-ttl {
  display: table-cell;
  vertical-align: middle;
  font-size: 1em;
}

#s-c-bn {
  padding: 0;
  width: 1.7em;
  height: 1.7em;
  font-size: 1.45em;
  margin: 0;
  font-weight: initial;
  position: relative;
  overflow: hidden;
}

#s-c-bnc {
  display: table-cell;
  vertical-align: middle;
}

.cc_div span.t-lb {
  position: absolute;
  top: 0;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
}

#c_policy__text {
  height: 31.25em;
  overflow-y: auto;
  margin-top: 1.25em;
}

#c-s-in {
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  height: 100%;
  height: calc(100% - 2.5em);
  max-height: 37.5em;
}

@media screen and (min-width: 688px) {
  /** works only on webkit-based browsers **/
  #s-bl::-webkit-scrollbar {
    width: 0.9em;
    height: 100%;
    background: transparent;
    border-radius: 0 0.25em 0.25em 0;
  }
  #s-bl::-webkit-scrollbar-thumb {
    border: 0.25em solid var(--cc-bg);
    background: var(--cc-webkit-scrollbar-bg);
    border-radius: 100em;
  }
  #s-bl::-webkit-scrollbar-thumb:hover {
    background: var(--cc-webkit-scrollbar-bg-hover);
  }
  #s-bl::-webkit-scrollbar-button {
    width: 10px;
    height: 5px;
  }
}
/** custom checkbox **/
/* The container */
.cc_div .b-tg {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: inline-block;
  margin: auto;
  right: 1.2em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
}

/* Hide the browser's default checkbox */
.cc_div .b-tg .c-tgl {
  position: absolute;
  cursor: pointer;
  display: block;
  top: 0;
  left: 0;
  margin: 0;
  border: 0;
}

/* Create a custom checkbox */
.cc_div .b-tg .c-tg {
  position: absolute;
  background: var(--cc-toggle-bg-off);
  transition: background-color 0.25s ease, box-shadow 0.25s ease;
  pointer-events: none;
}

.cc_div span.t-lb,
.cc_div .b-tg,
.cc_div .b-tg .c-tg,
.cc_div .b-tg .c-tgl {
  width: 3.4em;
  height: 1.5em;
  border-radius: 4em;
}

.cc_div .b-tg .c-tg.c-ro {
  cursor: not-allowed;
}

.cc_div .b-tg .c-tgl ~ .c-tg.c-ro {
  background: var(--cc-toggle-bg-readonly);
}

.cc_div .b-tg .c-tgl ~ .c-tg.c-ro:after {
  box-shadow: none;
}

/* Style the checkmark/indicator */
.cc_div .b-tg .c-tg:after {
  content: "";
  position: relative;
  display: block;
  left: 0.125em;
  top: 0.125em;
  width: 1.25em;
  height: 1.25em;
  border: none;
  box-sizing: content-box;
  background: var(--cc-toggle-knob-bg);
  box-shadow: 0 1px 2px rgba(24, 32, 35, 0.36);
  transition: transform 0.25s ease;
  border-radius: 100%;
}

/* Show the checkmark when checked */
.cc_div .b-tg .c-tgl:checked ~ .c-tg:after {
  transform: translateX(1.9em);
}

#s-bl table,
#s-bl th,
#s-bl td {
  border: none;
}

#s-bl tbody tr {
  transition: background-color 0.25s ease;
}

#s-bl tbody tr:hover {
  background: var(--cc-cookie-category-block-bg-hover);
}

#s-bl table {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

#s-bl td,
#s-bl th {
  padding: 0.8em 0.625em;
  text-align: left;
  vertical-align: top;
  font-size: 0.8em;
  padding-left: 1.2em;
}

#s-bl th {
  font-family: inherit;
  padding: 1.2em 1.2em;
}

#s-bl thead tr:first-child {
  border-bottom: 1px solid var(--cc-cookie-table-border);
}

.force--consent #s-cnt,
.force--consent #cs {
  width: 100vw;
}

#cm-ov,
#cs-ov {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  background: #070707;
  background: var(--cc-overlay-bg);
  display: none;
  transition: none;
}

.show--settings #cs-ov,
.c--anim #cs-ov,
.force--consent .c--anim #cm-ov,
.force--consent.show--consent #cm-ov {
  display: block;
}

#cs-ov {
  z-index: 2;
}

.force--consent .cc_div {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  width: 100vw;
  visibility: hidden;
  transition: visibility 0.25s linear;
}

.force--consent.show--consent .c--anim .cc_div,
.force--consent.show--settings .c--anim .cc_div {
  visibility: visible;
}

.force--consent #cm {
  position: absolute;
}

.force--consent #cm.bar {
  width: 100vw;
  max-width: 100vw;
}

html.force--consent.show--consent {
  overflow-y: hidden !important;
}

html.force--consent.show--consent,
html.force--consent.show--consent body {
  height: auto !important;
  overflow-x: hidden !important;
}

/** END BLOCK PAGE SCROLL */
/** BEGIN ICONS **/
.cc_div .b-bn .exp::before,
.cc_div .act .b-bn .exp::before {
  border: solid var(--cc-btn-secondary-text);
  border-width: 0 2px 2px 0;
  padding: 0.2em;
  display: inline-block;
  position: absolute;
  content: "";
  margin-right: 15px;
  position: absolute;
  transform: translateY(-50%) rotate(45deg);
  left: 1.2em;
  top: 50%;
}

.cc_div .act .b-bn .b-tl::before {
  transform: translateY(-20%) rotate(225deg);
}

.cc_div .on-i::before {
  border: solid var(--cc-toggle-knob-icon-color);
  border-width: 0 2px 2px 0;
  padding: 0.1em;
  display: inline-block;
  padding-bottom: 0.45em;
  content: "";
  margin: 0 auto;
  transform: rotate(45deg);
  top: 0.37em;
  left: 0.75em;
  position: absolute;
}

#s-c-bn::before,
#s-c-bn::after {
  content: "";
  position: absolute;
  left: 0.82em;
  top: 0.58em;
  height: 0.6em;
  width: 1.5px;
  background: var(--cc-btn-secondary-text);
  transform: rotate(45deg);
  border-radius: 1em;
  margin: 0 auto;
}

#s-c-bn::after {
  transform: rotate(-45deg);
}

.cc_div .off-i,
.cc_div .on-i {
  height: 100%;
  width: 50%;
  position: absolute;
  right: 0;
  display: block;
  text-align: center;
  transition: opacity 0.15s ease;
}

.cc_div .on-i {
  left: 0;
  opacity: 0;
}

.cc_div .off-i::before,
.cc_div .off-i::after {
  right: 0.8em;
  top: 0.42em;
  content: " ";
  height: 0.7em;
  width: 0.09375em;
  display: block;
  background: var(--cc-toggle-knob-icon-color);
  margin: 0 auto;
  position: absolute;
  transform-origin: center;
}

.cc_div .off-i::before {
  transform: rotate(45deg);
}

.cc_div .off-i::after {
  transform: rotate(-45deg);
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg .on-i {
  opacity: 1;
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg .off-i {
  opacity: 0;
}

/** END ICONS **/
#cm.box.middle,
#cm.cloud.middle {
  top: 50%;
  transform: translateY(-37%);
  bottom: auto;
}

#cm.box.middle.zoom,
#cm.cloud.middle.zoom {
  transform: scale(0.95) translateY(-50%);
}

#cm.box.center {
  left: 1em;
  right: 1em;
  margin: 0 auto;
}

/* Start cloud layout */
#cm.cloud {
  max-width: 50em;
  margin: 0 auto;
  text-align: center;
  left: 1em;
  right: 1em;
  overflow: hidden;
  padding: 1.3em 2em;
  width: unset;
}

.cc_div .cloud #c-inr {
  display: table;
  width: 100%;
}

.cc_div .cloud #c-inr-i {
  width: 70%;
  display: table-cell;
  vertical-align: top;
  padding-right: 2.4em;
}

.cc_div .cloud #c-txt {
  font-size: 0.85em;
}

.cc_div .cloud #c-bns {
  min-width: 170px;
  display: table-cell;
  vertical-align: middle;
}

#cm.cloud .c-bn {
  margin: 0.625em 0 0 0;
  width: 100%;
}

#cm.cloud .c-bn:first-child {
  margin: 0;
}

#cm.cloud.left {
  margin-right: 1.25em;
}

#cm.cloud.right {
  margin-left: 1.25em;
}

/* End cloud layout */
/* Start bar layout */
#cm.bar {
  width: 100%;
  max-width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
  position: fixed;
  padding: 2em;
}

#cm.bar #c-inr {
  max-width: 32em;
  margin: 0 auto;
}

#cm.bar #c-bns {
  max-width: 33.75em;
}

#cm.bar #cs {
  padding: 0;
}

.cc_div .bar #c-s-in {
  top: 0;
  transform: none;
  height: 100%;
  max-height: 100%;
}

.cc_div .bar #s-hdr,
.cc_div .bar #s-bl,
.cc_div .bar #s-bns {
  padding-left: 1.6em;
  padding-right: 1.6em;
}

.cc_div .bar #cs {
  padding: 0;
}

/* align bar to right by default */
.cc_div .bar #s-inr {
  margin: 0;
  margin-left: auto;
  margin-right: 0;
  border-radius: 0;
  max-width: 32em;
}

.cc_div .bar.left #s-inr {
  margin-left: 0;
  margin-right: auto;
}

/* Force table to not be like tables anymore */
.cc_div .bar #s-bl table,
.cc_div .bar #s-bl thead,
.cc_div .bar #s-bl tbody,
.cc_div .bar #s-bl th,
.cc_div .bar #s-bl td,
.cc_div .bar #s-bl tr,
.cc_div .bar #s-cnt {
  display: block;
}

/* Hide table headers (but not display: none;, for accessibility) */
.cc_div .bar #s-bl thead tr {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.cc_div .bar #s-bl tr {
  border-top: 1px solid var(--cc-cookie-table-border);
}

.cc_div .bar #s-bl td {
  /* Behave  like a "row" */
  border: none;
  position: relative;
  padding-left: 35%;
}

.cc_div .bar #s-bl td:before {
  position: absolute;
  left: 1em;
  padding-right: 0.625em;
  white-space: nowrap;
  content: attr(data-column);
  color: var(--cc-text);
  overflow: hidden;
  text-overflow: ellipsis;
}

/* End bar layout */
/* Positions */
#cm.top {
  bottom: auto;
  top: 1.25em;
}

#cm.left {
  right: auto;
  left: 1.25em;
}

#cm.right {
  left: auto;
  right: 1.25em;
}

#cm.bar.left,
#cm.bar.right {
  left: 0;
  right: 0;
}

#cm.bar.top {
  top: 0;
}

/* end positions */
@media screen and (max-width: 688px) {
  #cm,
  #cm.cloud,
  #cm.left,
  #cm.right {
    width: auto;
    max-width: 100%;
    margin: 0;
    padding: 1.4em !important;
    right: 1em;
    left: 1em;
    bottom: 1em;
    display: block;
  }
  .force--consent #cm,
  .force--consent #cm.cloud {
    width: auto;
    max-width: 100vw;
  }
  #cm.top {
    top: 1em;
    bottom: auto;
  }
  #cm.bottom {
    bottom: 1em;
    top: auto;
  }
  #cm.bar.bottom {
    bottom: 0;
  }
  #cm.cloud .c-bn {
    font-size: 0.85em;
  }
  #s-bns,
  .cc_div .bar #s-bns {
    padding: 1em 1.3em;
  }
  .cc_div .bar #s-inr {
    max-width: 100%;
    width: 100%;
  }
  .cc_div .cloud #c-inr-i {
    padding-right: 0;
  }
  #cs {
    border-radius: 0;
    padding: 0;
  }
  #c-s-in {
    max-height: 100%;
    height: 100%;
    top: 0;
    transform: none;
  }
  .cc_div .b-tg {
    transform: scale(1.1);
    right: 1.1em;
  }
  #s-inr {
    margin: 0;
    padding-bottom: 7.9em;
    border-radius: 0;
  }
  #s-bns {
    height: 7.9em;
  }
  #s-bl,
  .cc_div .bar #s-bl {
    padding: 1.3em;
  }
  #s-hdr,
  .cc_div .bar #s-hdr {
    padding: 0 1.3em;
  }
  /** dynamic table layout **/
  #s-bl table {
    width: 100%;
  }
  #s-inr.bns-t {
    padding-bottom: 10.5em;
  }
  .bns-t #s-bns {
    height: 10.5em;
  }
  .cc_div .bns-t .c-bn {
    font-size: 0.83em;
    padding: 0.9em 1.6em;
  }
  #s-cnt .b-bn .b-tl {
    padding-top: 1.2em;
    padding-bottom: 1.2em;
  }
  /* Force table to not be like tables anymore */
  #s-bl table,
  #s-bl thead,
  #s-bl tbody,
  #s-bl th,
  #s-bl td,
  #s-bl tr,
  #s-cnt {
    display: block;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  #s-bl thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #s-bl tr {
    border-top: 1px solid var(--cc-cookie-table-border);
  }
  #s-bl td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding-left: 35%;
  }
  #s-bl td:before {
    position: absolute;
    left: 1em;
    padding-right: 0.625em;
    white-space: nowrap;
    content: attr(data-column);
    color: var(--cc-text);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #cm .c-bn,
  .cc_div .c-bn {
    width: 100%;
    margin-right: 0;
  }
  #s-cnt #s-rall-bn {
    margin-left: 0;
  }
  .cc_div #c-bns {
    flex-direction: column;
  }
  #c-bns button + button,
  #s-cnt button + button {
    margin-top: 0.625em;
    margin-left: 0;
    float: unset;
  }
  #cm.cloud,
  #cm.box {
    left: 1em;
    right: 1em;
    width: auto;
  }
  #cm.cloud.right,
  #cm.cloud.left {
    margin: 0;
  }
  .cc_div .cloud #c-bns,
  .cc_div .cloud #c-inr,
  .cc_div .cloud #c-inr-i {
    display: block;
    width: auto;
    min-width: unset;
  }
  .cc_div .cloud #c-txt {
    font-size: 0.9em;
  }
  .cc_div .cloud #c-bns {
    margin-top: 1.625em;
  }
}
/* Begin IE fixes */
.cc_div.ie #c-vln {
  height: 100%;
  padding-top: 5.62em;
}

.cc_div.ie .bar #c-vln {
  padding-top: 0;
}

.cc_div.ie #cs {
  max-height: 37.5em;
  position: relative;
  top: 0;
  margin-top: -5.625em;
}

.cc_div.ie .bar #cs {
  margin-top: 0;
  max-height: 100%;
}

.cc_div.ie #cm {
  border: 1px solid #dee6e9;
}

.cc_div.ie #c-s-in {
  top: 0;
}

.cc_div.ie .b-tg {
  padding-left: 1em;
  margin-bottom: 0.7em;
}

.cc_div.ie .b-tg .c-tgl:checked ~ .c-tg:after {
  left: 1.95em;
}

.cc_div.ie #s-bl table {
  overflow: auto;
}

.cc_div.ie .b-tg .c-tg {
  display: none;
}

.cc_div.ie .b-tg .c-tgl {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0.2em;
  height: auto;
}

.cc_div.ie #s-cnt .b-bn .b-tl {
  padding: 1.3em 6.4em 1.3em 1.4em;
}

.cc_div.ie .bar #s-bl td:before {
  display: none;
}

.cc_div.ie .bar #s-bl td {
  padding: 0.8em 0.625em 0.8em 1.2em;
}

.cc_div.ie .bar #s-bl thead tr {
  position: relative;
}

.cc_div.ie .b-tg .t-lb {
  filter: alpha(opacity=0);
}

.cc_div.ie #cm-ov,
.cc_div.ie #cs-ov {
  filter: alpha(opacity=80);
}

/** END IE FIXES **/
.headline-with-cloud {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  position: relative;
  text-align: center;
  padding: 30px 0;
}
.headline-with-cloud span {
  position: relative;
  z-index: 3;
  display: inline-block;
  width: 270px;
  font-size: 30px;
  line-height: 44px;
}
.headline-with-cloud span:before {
  left: 0;
}
.headline-with-cloud span:after {
  right: 0;
}
.headline-with-cloud span:after, .headline-with-cloud span:before {
  content: "";
  position: absolute;
  width: 7px;
  height: 3px;
  top: 50%;
  transform: translate(0, -50%);
  background-color: #ffffff;
  z-index: 2;
}
.headline-with-cloud:after {
  width: 270px;
  height: 100px;
  content: "";
  background-color: #5543c1;
  mask-image: url("/assets/images/icon-cloud.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.headline-with-cloud:before {
  width: 100%;
  height: 3px;
  content: "";
  background: #F24C07;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.headline-with-elipse {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  position: relative;
  text-align: center;
  padding: 10px 0;
}
.headline-with-elipse span {
  background-color: #5543c1;
  border-radius: 50%;
  position: relative;
  z-index: 3;
  min-width: 270px;
  display: inline-block;
  padding: 20px 50px;
  border: 10px solid #ebeeee;
  font-size: 30px;
  line-height: 44px;
}
.headline-with-elipse:before {
  width: 100%;
  height: 3px;
  content: "";
  background: #F24C07;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

h1 {
  color: #5cb0a5;
  font-weight: 400;
  border-bottom: 2px solid #5cb0a5;
  text-align: center;
  padding: 40px 0 10px 0;
  margin-bottom: 20px;
}

.homepage-padding-content {
  padding: 70px 0;
}
.homepage-padding-content .headline-with-cloud {
  margin-bottom: 40px;
}

.link-as-button {
  display: inline-block;
  background: #5cb0a5;
  color: #ffffff;
  transition: 300ms;
  padding: 10px 30px;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.link-as-button:hover {
  background: #4c9c92;
  color: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.link-as-button i {
  color: #ffffff;
  padding-right: 10px;
}

.homepage-middle-content {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1360px) {
  .homepage-middle-content {
    margin: 0 20px;
  }
}
@media (max-width: 1000px) {
  .homepage-middle-content {
    flex-wrap: wrap;
  }
}
.homepage-middle-content__left {
  width: 60%;
}
@media (max-width: 1000px) {
  .homepage-middle-content__left {
    width: 100%;
  }
}
.homepage-middle-content__right {
  width: 30%;
}
@media (max-width: 1000px) {
  .homepage-middle-content__right {
    width: 100%;
  }
}

.basic-page img {
  height: auto;
}

.footer-copyright {
  padding: 10px 0;
  color: #1c1c1c;
  font-size: 13px;
  line-height: 27px;
  text-align: center;
}
.footer-copyright .footer-copyright__content {
  text-align: center;
}
@media (max-width: 1360px) {
  .footer-copyright {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media (max-width: 550px) {
  .footer-copyright .footer-copyright__content {
    flex-wrap: wrap;
  }
  .footer-copyright .footer-copyright__content .footer-copyright__list-delivery-payment-icons,
  .footer-copyright .footer-copyright__content .footer-copyright__text {
    width: 100%;
    text-align: center;
  }
  .footer-copyright .footer-copyright__content .footer-copyright__text {
    order: 2;
    padding-top: 10px;
  }
  .footer-copyright .footer-copyright__content .footer-copyright__list-delivery-payment-icons {
    order: 1;
  }
  .footer-copyright .footer-copyright__content .footer-copyright__list-delivery-payment-icons ul {
    justify-content: center;
  }
  .footer-copyright .footer-copyright__content .footer-copyright__list-delivery-payment-icons ul li {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.footer-text {
  padding: 20px 0;
  background: #555555;
  color: #a6a6a6;
  font-size: 13px;
  line-height: 27px;
  text-align: center;
}
.footer-text .footer-text__content {
  text-align: center;
}
@media (max-width: 1360px) {
  .footer-text {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media (max-width: 550px) {
  .footer-text .footer-text__content {
    flex-wrap: wrap;
  }
  .footer-text .footer-text__content .footer-text__list-delivery-payment-icons,
  .footer-text .footer-text__content .footer-text__text {
    width: 100%;
    text-align: center;
  }
  .footer-text .footer-text__content .footer-text__text {
    order: 2;
    padding-top: 10px;
  }
  .footer-text .footer-text__content .footer-text__list-delivery-payment-icons {
    order: 1;
  }
  .footer-text .footer-text__content .footer-text__list-delivery-payment-icons ul {
    justify-content: center;
  }
  .footer-text .footer-text__content .footer-text__list-delivery-payment-icons ul li {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.footer-content {
  padding: 60px 0 20px 0;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1360px) {
  .footer-content {
    flex-wrap: wrap;
  }
}
.footer-content .footer-content__column {
  font-weight: bold;
}
.footer-content .footer-content__column.footer-menu {
  align-self: flex-end;
}
.footer-content .footer-content__column .footer-content__column--content {
  padding: 20px 0;
}
.footer-content .footer-content__column a {
  color: #ffffff;
  text-decoration: none;
}
.footer-content .footer-content__column a:hover {
  text-decoration: underline;
}
.footer-content .footer-content__column h3 {
  text-transform: uppercase;
}
.footer-content .footer-content__column ul {
  list-style: none;
  display: flex;
}
.footer-content .footer-content__column ul li {
  padding-right: 10px;
}
.footer-content .footer-content__column dl {
  display: flex;
}
.footer-content .footer-content__column dl dt, .footer-content .footer-content__column dl dd {
  padding: 5px 0;
}
.footer-content .footer-content__column dl dt {
  padding-left: 10px;
  padding-right: 10px;
}
.footer-content .footer-content__column dl i {
  font-size: 25px;
}
@media (max-width: 1360px) {
  .footer-content {
    padding: 0 5%;
  }
}
@media (max-width: 1000px) {
  .footer-content {
    padding: 0 3%;
    flex-wrap: wrap;
  }
  .footer-content .footer-content__column {
    width: 100%;
    padding: 0 2%;
  }
  .footer-content .footer-content__column a {
    display: block;
  }
  .footer-content .footer-content__column dl dt, .footer-content .footer-content__column dl dd {
    padding: 12px 10px 7px 10px;
    margin: 2px 0;
  }
  .footer-content .footer-content__column:not(.contact) a {
    padding: 12px 10px;
  }
  .footer-content .footer-content__column:not(.contact) ul li {
    width: 100%;
    padding: 2px 0;
  }
}
@media (max-width: 730px) {
  .footer-content {
    text-align: center;
  }
  .footer-content .footer-content__column dl {
    flex-wrap: wrap;
  }
  .footer-content .footer-content__column dl dt {
    width: 40px;
  }
  .footer-content .footer-content__column dl dd {
    width: calc(100% - 40px);
  }
  .footer-content .footer-content__column.footer-menu ul li {
    padding-bottom: 10px;
  }
  .footer-content .footer-content__column.footer-menu ul li a {
    background: #e34707;
  }
  .footer-content .footer-content__column:not(.contact) ul {
    display: block;
  }
}
@media (max-width: 550px) {
  .footer-content .footer-content__column {
    width: 100%;
  }
  .footer-content .footer-content__column.contact {
    width: 100%;
  }
}

.footer-subscribe-newsletter {
  text-align: center;
  border-bottom: 1px solid #323232;
  padding: 20px 0 30px 0;
  margin-bottom: 30px;
}
.footer-subscribe-newsletter .footer-subscribe-newsletter__form {
  width: 40%;
  margin: 0 auto;
  padding-top: 20px;
  text-align: left;
}
.footer-subscribe-newsletter .footer-subscribe-newsletter__form a {
  color: #c2c2c2;
}
.footer-subscribe-newsletter .footer-subscribe-newsletter__form p {
  font-size: 12px;
  line-height: 26px;
}
.footer-subscribe-newsletter .footer-subscribe-newsletter__form .footer-subscribe-newsletter__form--elements {
  display: flex;
}
.footer-subscribe-newsletter .footer-subscribe-newsletter__form .footer-subscribe-newsletter__form--elements input {
  border: 1px solid #323232;
  flex-grow: 1;
  padding: 10px 20px;
  background: transparent;
  color: #c2c2c2;
}
.footer-subscribe-newsletter .footer-subscribe-newsletter__form .footer-subscribe-newsletter__form--elements button {
  border: none;
  background: #323232;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0 20px;
  cursor: pointer;
}
.footer-subscribe-newsletter .footer-subscribe-newsletter__form .footer-subscribe-newsletter__form--elements button:hover {
  text-decoration: underline;
}
@media (max-width: 1360px) {
  .footer-subscribe-newsletter .footer-subscribe-newsletter__form {
    width: 60%;
  }
}
@media (max-width: 1000px) {
  .footer-subscribe-newsletter .footer-subscribe-newsletter__form {
    width: 90%;
  }
}
@media (max-width: 550px) {
  .footer-subscribe-newsletter .footer-subscribe-newsletter__form .footer-subscribe-newsletter__form--elements {
    flex-wrap: wrap;
  }
  .footer-subscribe-newsletter .footer-subscribe-newsletter__form .footer-subscribe-newsletter__form--elements input, .footer-subscribe-newsletter .footer-subscribe-newsletter__form .footer-subscribe-newsletter__form--elements button {
    width: 100%;
  }
  .footer-subscribe-newsletter .footer-subscribe-newsletter__form .footer-subscribe-newsletter__form--elements button {
    padding: 5px 20px;
    margin-top: 10px;
  }
}
@media (max-width: 1000px) {
  .footer-subscribe-newsletter h2, .footer-subscribe-newsletter p {
    padding-left: 5%;
    padding-right: 5%;
  }
}

footer {
  margin-top: 40px;
  color: #ffffff;
}
footer h2 {
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 300;
  font-size: 25px;
  line-height: 39px;
}