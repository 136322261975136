.audio-book-list
  display: flex
  flex-wrap: wrap

.audio-book-item
  $this: &

  margin: 0.5%
  width: 24%
  padding: $paddingBase
  text-align: center
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
  transition: 300ms

  @include second-breakpoint
    width: 49%

  @include smallest-view
    width: 100%

  &:hover
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px
    transform: scale(1.01)

  a
    display: block
    text-decoration: none

  #{$this}__image,
  #{$this}__name
    display: block

  #{$this}__name
    background: $color_green04
    padding: $paddingBase*1.5 $paddingBase
    color: $color_white
    @include setFontSize(20)

  #{$this}__image

    img
      display: block
      margin: 0 auto
      max-width: 100%
