.base-button,
.base-button-transparent,
.base-button-action-colored
  display: inline-block
  border: 2px solid transparent
  font-weight: bold
  text-transform: uppercase
  font-size: 14px
  cursor: pointer
  padding: $paddingBase $paddingBase*3
  transition: 300ms
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px

.base-button-action-colored
  background: $color_red2
  color: $color_white
  border-color: $color_red2
  &:hover
    color: $color_white
    background: $color_red06
    border-color: $color_red06

.base-button
  background: $color_gray01
  color: $color_white
  border-color: $color_gray01
  &:hover
    color: $color_gray01
    background: transparent

.base-button-transparent
  color: $color_gray01
  background: transparent
  border-color: $color_gray01
  &:hover
    color: $color_white
    background: $color_gray01
