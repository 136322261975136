form
  .form-item
    padding: $paddingBase*0.5 0
    &.required
      label
        font-weight: 900
        &:after
          content: '*'
    label
      color: $color_gray02
      display: block
    input, select, textarea
      color: $color_gray02
      border: 1px solid $color_gray06
      padding: $paddingBase*0.5 $paddingBase*1.5
    select
      padding-top: $paddingBase*1.5
      padding-bottom: $paddingBase*1.5
    &.error
      label
        color: $color_red
      input, select, textarea
        border-color: $color_red
    .error-message
      color: $color_red

.form-list-of-links
  display: inline-block
  position: relative
  min-height: $fontSizeBase
  .form-list-of-links__list
    border: 1px solid $color_black
    display: block
    a
      display: none
      &.active
        display: block
  &:hover
    .form-list-of-links__list
      position: absolute
      z-index: 2
      background: $color_white
      left: 0
      top: 0
      white-space: nowrap
      a
        display: block