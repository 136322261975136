.main-menu
  display: flex
  align-items: stretch
  height: 100%
  @include second-breakpoint
    display: none

  &.open-mobile-menu
    @include second-breakpoint
      display: block
      position: absolute
      top: 100%
      left: 0
      width: 100%
      background: $color_white
      border-bottom: 4px solid $color_green04
      height: auto
      box-shadow: rgba(33, 35, 38, 0.9) 0px 10px 10px -10px

  &__search-button
    display: flex
    align-items: center

    @include second-breakpoint
      text-align: center
      justify-content: center
      padding: $paddingBase 0 !important

    &.is-homepage
      display: none

    span
      padding: $paddingBase*1.5
      background: $color_green04
      display: flex
      justify-content: center
      align-items: center
      border-radius: 50%
      cursor: pointer
      transition: 300ms

      &:hover
        background: darken($color_green04, 6)
        transform: scale(1.2)

      svg
        $size: 25px
        width: $size
        height: $size
        color: $color_white

  &__search-button,
  &__category,
  &__pages
    padding: 0 $paddingBase

    a
      text-decoration: none
      display: block
      padding: $paddingBase $paddingBase*2
      @include setFontSize(20)
      transition: 300ms

  &__category
    display: flex
    align-items: stretch
    border-right: 1px solid $color_green04

    a
      @include second-breakpoint
        text-align: center
        background: $color_green04
        border-radius: 5px
        color: $color_white

      &:hover
        background: $color_green04
        border-radius: 5px
        color: $color_white

    &--icon

      svg
        $size: 50px
        width: $size
        height: $size
        color: $color_green04

    &--icon,
    &--list
      display: flex
      align-items: center

    &--list
      @include second-breakpoint
        width: 100%

      ul
        list-style: none
        display: flex
        align-items: center
        @include second-breakpoint
          width: 100%
          flex-wrap: wrap

        li
          padding: 0 $paddingBase

          @include second-breakpoint
            width: 50%
            padding: $paddingBase

  &__pages
    display: flex
    align-items: center
    @include second-breakpoint
      display: block

    a
      @include second-breakpoint
        text-align: center

      &:hover
        border-bottom: 5px solid $color_green04

    ul
      list-style: none
      display: flex

      @include second-breakpoint
        display: block

        li
          border-top: 1px solid $color_green04

//.menu
  padding-left: 20px
  @include third-breakpoint
    display: none
    &.open-mobile-menu
      display: block
  @import 'menu-horizontal'
  @import 'menu-vertical'

.mobile-menu-button
  @include min-first-breakpoint
    display: none
  width: 50px
  height: 40px
  text-align: center
  line-height: normal
  display: flex
  justify-content: center
  align-items: center
  background: $color_green04
  margin-left: $paddingBase*2
  i
    display: block
    font-size: 30px
    margin-top: 3px
    color: $color_white
    &:before
      @include addIcon($pe7s_menu)
  &.is-open
    i
      &:before
        @include addIcon($pe7s_close)


//.aknihy-style

  ul
    width: 100%
    //li
      padding: 0 $paddingBase*2

  a
    color: $color_black
    text-decoration: none
    font-family: $fontFamilyBaseRuddy
    font-weight: bold
    position: relative
    padding: 0 $paddingBase*5
    display: block
    @include setFontSize(17)
    transition: 300ms

    &:hover
      background: $color_green04
      color: $color_white


  @include third-breakpoint
    position: absolute
    top: 100%
    left: 0
    width: 100%
    background: $color_orange01
    box-shadow: 0px 16px 24px 0px rgba(57, 46, 122, 0.33)
    z-index: 3

    ul
      flex-wrap: wrap

      li
        padding: 0 $paddingBase*2
        width: 50%
        text-align: center
        &:nth-child(2),
        &:nth-child(3)
          background: $color_purple02


