.footer-text
  padding: $paddingBase*2 0
  background: $color_gray02
  color: $color_gray16
  @include setFontSize(13)
  text-align: center
  .footer-text__content
    text-align: center
  @include first-breakpoint
    padding-left: 5%
    padding-right: 5%
  @include smallest-view
    .footer-text__content
      flex-wrap: wrap
      .footer-text__list-delivery-payment-icons,
      .footer-text__text
        width: 100%
        text-align: center
      .footer-text__text
        order: 2
        padding-top: $paddingBase
      .footer-text__list-delivery-payment-icons
        order: 1
        ul
          justify-content: center
          li
            padding-left: $paddingBase
            padding-right: $paddingBase