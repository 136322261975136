.dn
  display: none

.content-width
  width: $defaultWidth
  margin: 0 auto
  @include first-breakpoint
    width: 100%
    padding: 0 $paddingBase*2

html, body
  font-family: $fontFamilyBase
  font-size: $fontSizeBase
  line-height: $lineHeightBase

a
  color: $color_black
  text-decoration: underline
  &:hover
    color: $color_black