.carousel
  .carousel__item
    > a
      position: relative
      display: block
      .carousel__text-content
        position: absolute
        left: 0
        top: 0
        background: rgba(255,255,255, .6)
        .carousel__text-content--headline
          display: block
        .carousel__text-content--text
          display: block
        .carousel__text-content--button
          display: inline-block