@mixin headlineWithCloud()
  color: $color_white
  text-transform: uppercase
  font-weight: bold
  font-family: $fontFamilyBaseRuddy
  position: relative
  text-align: center
  padding: $paddingBase*3 0

  $width: 270px
  $height: 100px
  $lineHeight: 3px

  span
    position: relative
    z-index: 3
    display: inline-block
    width: $width
    @include setFontSize(30)

    &:before
      left: 0

    &:after
      right: 0

    &:after,
    &:before
      content: ''
      position: absolute
      width: 7px
      height: $lineHeight
      top: 50%
      transform: translate(0, -50%)
      background-color: $color_white
      z-index: 2


  &:after
    width: $width
    height: $height
    content: ''
    background-color: $color_purple02
    mask-image: url('/assets/images/icon-cloud.svg')
    mask-repeat: no-repeat
    mask-position: center
    position: absolute
    z-index: 2

    left: 50%
    top: 50%
    transform: translate(-50%, -50%)

  &:before
    width: 100%
    height: $lineHeight
    content: ''
    background: $color_orange01
    position: absolute
    z-index: 1
    left: 0
    top: 50%
    transform: translate(0, -50%)

@mixin headlineWithElipse()
  color: $color_white
  text-transform: uppercase
  font-weight: bold
  font-family: $fontFamilyBaseRuddy
  position: relative
  text-align: center
  padding: $paddingBase 0

  $lineHeight: 3px

  span
    background-color: $color_purple02
    border-radius: 50%
    position: relative
    z-index: 3
    min-width: 270px
    display: inline-block
    padding: $paddingBase*2 $paddingBase*5
    border: 10px solid $color_gray15
    @include setFontSize(30)

  &:before
    width: 100%
    height: $lineHeight
    content: ''
    background: $color_orange01
    position: absolute
    z-index: 1
    left: 0
    top: 50%
    transform: translate(0, -50%)