.gallery-list
  display: flex
  padding: $paddingBase*3 0

  .gallery-item
    padding: $paddingBase

    img
      box-shadow: 0px 16px 24px 0px rgba(57, 46, 122, 0.33)

    &:first-of-type
      padding-left: 0