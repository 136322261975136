.audio-catalog-detail
  display: flex
  //flex-wrap: wrap

  @include second-breakpoint
    flex-wrap: wrap

  &__info
    //display: flex
    //flex-grow: 1
    //flex-wrap: wrap

    $orderBreakpoint: 1100px

    //@media (max-width: $orderBreakpoint)
      flex-wrap: wrap

    @include first-breakpoint
      flex-grow: 1
      width: 50%

    //&--parameters
      flex-grow: 1

    &--image
      //text-align: right
      padding-bottom: $paddingBase*3

      img
        display: block
        //max-width: 500px
        //box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px

        //@media (max-width: 1250px)
          max-width: 350px

        //@media (max-width: $orderBreakpoint)
          max-width: 100%

        @include second-breakpoint
          margin: 0 auto

    //&--parameters
      order: 1

      @include first-breakpoint
        order: 2
        width: 100%
        padding-top: $paddingBase*3


      //@media (max-width: $orderBreakpoint)
        order: 2
        width: 100%
        padding-top: $paddingBase*3


    //&--image
      order: 2

      @include first-breakpoint
        order: 1
        width: 100%

      //@media (max-width: $orderBreakpoint)
        order: 1
        width: 100%

  &__description
    width: 100%
    padding-top: $paddingBase*3
    order: 3

  &__player
    padding-right: $paddingBase*5

    &--download-all
      padding-top: $paddingBase*3
      text-align: center

    @include second-breakpoint
      padding-bottom: $paddingBase*2
      padding-right: 0
      width: 100%

  @include second-breakpoint

    &__info
      order: 1

    &__player
      order: 2
      padding-top: $paddingBase*2

    //&__description
    //  order: 3

.audio-catalog-detail-parameters

  &.links
    display: flex
    align-items: center

  &__name
    font-weight: bold
    display: inline-block
    width: 130px

.audio-catalog-detail-link
  display: flex
  flex-wrap: wrap
  padding-top: $paddingBase
  align-items: center

  @include second-breakpoint
    justify-content: center

  &__item
    padding-right: $paddingBase*2

    a
      display: inline-block
      transition: 700ms

      i, img
        transition: 700ms

      &:hover
        transform: scale(1.1)

        i, img
          transform: scale(1.1)

    i
      font-size: 30px
      color: $color_black

    img
      display: block
      height: 40px
      line-height: 40px

    //&.link-youtube

      a
        display: flex
        align-items: center

      i
        color: $color_red07
        font-size: 40px


    //&.link-spotify

      a
        display: flex
        align-items: center

      i
        color: $color_green05
        font-size: 40px