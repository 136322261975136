.headline-content-banner
  background: $color_gray07
  padding: $paddingBase*2 0
  margin: 0 0 $paddingBase*4 0
  text-align: center
  h1
    @include setFontSize(40)
    span
      display: block
      @include setFontSize(18)
      font-weight: normal
