.search-form--close
  text-align: center
  display: none
  padding-top: $paddingBase*3

  svg
    $size: 40px
    width: $size
    height: $size
    color: $color_gray08
    cursor: pointer
    transition: 300ms

    &:hover
      color: lighten($color_gray08, 8)
      transform: scale(1.2)

.search-overload
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  z-index: 4
  background: rgba(255, 255, 255, .8)
  display: none

  &.show
    display: block


.search-form
  $radius: 25px

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px
  width: 50%
  border-radius: $radius
  background: $color_green04
  margin: 0 auto
  padding: $paddingBase*1.5 0
  //padding-left: 20px
  //@include third-and-half-breakpoint
    padding-left: 0

  @include smallest-view
    display: none
    padding: $paddingBase $paddingBase*0.5
    width: 95%

  form
    text-align: center

    button
      border: none
      background: transparent
      //@include third-and-half-breakpoint
        border-radius: $radius

      cursor: pointer
      padding-left: $paddingBase

      //&:hover
        background: lighten($color_purple02, 4)

      svg
        $size: 27px
        width: $size
        height: $size
        color: $color_white
        position: relative
        top: 3px
        left: -3px
        //@include third-and-half-breakpoint
          left: 0

    input
      background: transparent
      border: none
      font-size: 25px
      color: $color_white
      width: 88%

      //&:focus
        border-bottom-color: $color_green04

      @include third-and-half-breakpoint
        width: 100px

      @include third-breakpoint
        width: 200px

      @include smallest-view
        width: 75%

      //@include third-and-half-breakpoint
        display: none

      &:focus
        outline: none

      &::placeholder
        color: $color_white

.search-container
  padding: $paddingBase*4 0

  @include smallest-view
    padding: 0

  &.hide
    display: none

  &.open
    width: 100%
    position: fixed
    left: 50%
    transform: translateX(-50%)
    z-index: 6

    @include smallest-view

      .search-form
        display: block

    .search-form--close
      display: block
