.news-carousel
  box-shadow: 0px 16px 24px 0px rgba(57, 46, 122, 0.33)
  background-color: $color_gray15

  @include second-breakpoint

    .splide__arrow--next
      right: 0

    .splide__arrow--prev
      left: 0

  .splide__pagination
    position: absolute
    top: 20%
    bottom: auto

    @include second-breakpoint
      top: auto
      bottom: 3%

    &__page
      background: $color_white

      @include second-breakpoint
        background: $color_orange01

      &.is-active
        transform: scale(1.8)
        @include second-breakpoint
          background: $color_orange01
  
  .splide__arrows

    .splide__arrow
      background: transparent
      top: 12%
      transform: translateY(-12%)

      @include second-breakpoint
        top: 50%
        transform: translateY(-50%)

      svg
        $size: 30px
        width: $size
        height: $size
        fill: $color_purple02


  &__item
    $paddingLeftRight: $paddingBase*4
    color: $color_purple02
    min-height: 458px

    &--image

      @include second-breakpoint
        text-align: center

      image
        display: block
        max-width: 100%

        @include second-breakpoint
          margin: 0 auto

    &--headline
      padding: 30px $paddingLeftRight 0 $paddingLeftRight
      font-weight: bold
      text-transform: uppercase
      font-family: $fontFamilyBaseRuddy
      @include setFontSize(24)

    &--date
      padding: 0 $paddingLeftRight 15px $paddingLeftRight
      font-weight: bold
      @include setFontSize(16)

    &--description
      padding: 0 $paddingLeftRight 20px $paddingLeftRight
      @include setFontSize(16)
      line-height: 150%

    &--button
      padding: 20px $paddingLeftRight 40px $paddingLeftRight

      a
        @include dottedButton(primary)