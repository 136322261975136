.footer-copyright
  padding: $paddingBase 0
  color: $color_gray01
  @include setFontSize(13)
  text-align: center
  .footer-copyright__content
    text-align: center
  @include first-breakpoint
    padding-left: 5%
    padding-right: 5%
  @include smallest-view
    .footer-copyright__content
      flex-wrap: wrap
      .footer-copyright__list-delivery-payment-icons,
      .footer-copyright__text
        width: 100%
        text-align: center
      .footer-copyright__text
        order: 2
        padding-top: $paddingBase
      .footer-copyright__list-delivery-payment-icons
        order: 1
        ul
          justify-content: center
          li
            padding-left: $paddingBase
            padding-right: $paddingBase