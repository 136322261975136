header
  //padding: $paddingBase 0
  background: $color_white
  //border-bottom: 2px solid $color_green04
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
  padding: $paddingBase 0
  position: relative

  @include first-breakpoint
    position: relative
    z-index: 4
    //padding-bottom: $paddingBase*2
    //> div
    //  padding-bottom: $paddingBase
    //  box-shadow: rgba(33, 35, 38, 0.2) 0 10px 10px -10px
  > div
    .header__inner
      display: flex
      align-items: stretch
      justify-content: space-between

    @include min-first-breakpoint
      position: relative

  //.header__logo-container,
  //.header__content-container
    padding: $paddingBase*1.5 0

  .header__logo-container
    //width: 20px
    display: flex
    align-items: center

  .header__content-container
    display: flex
    //background: $color_orange01
    align-items: center
    //flex-grow: 1

    @include third-and-half-breakpoint
      justify-content: flex-end