@mixin dottedButton($type: primary)
  $transitionTime: 300ms

  background-color: $color_orange01
  text-decoration: none
  text-align: center
  display: flex
  justify-content: center
  align-items: center
  width: 80px
  border-radius: 20px
  padding: 11px 0
  transition: $transitionTime

  span
    transition: $transitionTime
    $size: 8px
    width: $size
    height: $size
    border-radius: 50%
    margin: 0 4px
    background-color: $color_purple02

  &:hover
    background-color: $color_purple02

    span
      background-color: $color_orange01

  @if $type == secondary
    width: 120px
    padding: 15px 0
    box-shadow: 0 16px 24px 0 rgba(57, 46, 122, 0.33)

    span
      $size: 12px
      width: $size
      height: $size


@mixin button($type: primary)

  $darkenPercentage: 4

  display: inline-block

  &:hover
    text-decoration: none


  @if $type == primary
    color: $color-white
    background: $color_purple02
    text-align: center
    border-radius: 20px
    padding: $paddingBase $paddingBase*5
    font-family: $fontFamilyBaseRuddy
    font-weight: bold
    text-decoration: none
    @include setFontSize(40)
    position: relative

    @include third-breakpoint
      padding: $paddingBase $paddingBase*2

    &:after
      display: block
      width: 61px
      height: 45px
      content: ''
      background-image: url('/assets/images/ico-arrow-down.png')
      position: absolute
      z-index: 2
      left: 50%
      bottom: -70px
      transform: translate(-50%, 0)

      @include second-breakpoint
        width: 30px
        height: 22px
        background-size: cover
        bottom: -40px

    &:hover
      text-decoration: none
      background: darken($color_purple02, $darkenPercentage)

      &:after
        animation: downUpAnimation 1s linear infinite

        @keyframes downUpAnimation

          0%, 100%
            transform: translate(-50%, 0)

          50%
            transform: translate(-50%, 10px)


  @else if $type == secondary
    color: $color_purple02
    background: $color_orange01

    &:hover
      background: darken($color_orange01, $darkenPercentage)