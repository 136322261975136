@font-face
  font-family: 'jost'
  src: url($dirFonts + 'jost-400-book-webfont.woff2') format('woff2'), url($dirFonts + 'jost-400-book-webfont.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'jost'
  src: url($dirFonts + 'jost-500-medium-webfont.woff2') format('woff2'), url($dirFonts + 'jost-500-medium-webfont.woff') format('woff')
  font-weight: 700
  font-style: normal

@font-face
  font-family: 'jost'
  src: url($dirFonts + 'jost-700-bold-webfont.woff2') format('woff2'), url($dirFonts + 'jost-700-bold-webfont.woff') format('woff')
  font-weight: 900
  font-style: normal

//@font-face {
//  font-family: 'josthairline';
//  src: url('jost-100-hairline-webfont.woff2') format('woff2'),
//  url('jost-100-hairline-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//
//}
//
//@font-face {
//  font-family: 'josthairline_italic';
//  src: url('jost-100-hairlineitalic-webfont.woff2') format('woff2'),
//  url('jost-100-hairlineitalic-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//
//}
//
//@font-face {
//  font-family: 'jostthin';
//  src: url('jost-200-thin-webfont.woff2') format('woff2'),
//  url('jost-200-thin-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//
//}
//
//@font-face {
//  font-family: 'jostthin_italic';
//  src: url('jost-200-thinitalic-webfont.woff2') format('woff2'),
//  url('jost-200-thinitalic-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//
//}
//
//@font-face {
//  font-family: 'jostlight';
//  src: url('jost-300-light-webfont.woff2') format('woff2'),
//  url('jost-300-light-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'jostlight_italic';
//  src: url('jost-300-lightitalic-webfont.woff2') format('woff2'),
//  url('jost-300-lightitalic-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'jostitalic';
//  src: url('jost-400-bookitalic-webfont.woff2') format('woff2'),
//  url('jost-400-bookitalic-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//
//@font-face {
//  font-family: 'jostmedium_italic';
//  src: url('jost-500-mediumitalic-webfont.woff2') format('woff2'),
//  url('jost-500-mediumitalic-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'jostsemi';
//  src: url('jost-600-semi-webfont.woff2') format('woff2'),
//  url('jost-600-semi-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'jostsemi_italic';
//  src: url('jost-600-semiitalic-webfont.woff2') format('woff2'),
//  url('jost-600-semiitalic-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'jostbold';
//  src: url('jost-700-bold-webfont.woff2') format('woff2'),
//  url('jost-700-bold-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'jostbold_italic';
//  src: url('jost-700-bolditalic-webfont.woff2') format('woff2'),
//  url('jost-700-bolditalic-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'jostheavy';
//  src: url('jost-800-heavy-webfont.woff2') format('woff2'),
//  url('jost-800-heavy-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'jostheavy_italic';
//  src: url('jost-800-heavyitalic-webfont.woff2') format('woff2'),
//  url('jost-800-heavyitalic-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'jostblack';
//  src: url('jost-900-black-webfont.woff2') format('woff2'),
//  url('jost-900-black-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//
//@font-face {
//  font-family: 'jostblack_italic';
//  src: url('jost-900-blackitalic-webfont.woff2') format('woff2'),
//  url('jost-900-blackitalic-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}