.footer-content
  padding: $paddingBase*6 0 $paddingBase*2 0
  display: flex
  justify-content: space-between
  @include first-breakpoint
    flex-wrap: wrap
  .footer-content__column
    //color: $color_purple02
    font-weight: bold

    @import "footer-menu"

    .footer-content__column--content
      padding: $paddingBase*2 0
    a
      color: $color_white
      text-decoration: none
      &:hover
        text-decoration: underline
    h3
      text-transform: uppercase
      //color: $color_purple02
    ul
      list-style: none
      display: flex
      li
        padding-right: $paddingBase
    dl
      display: flex
      dt,dd
        padding: $paddingBase*0.5 0
      dt
        padding-left: $paddingBase
        padding-right: $paddingBase
      i
        font-size: 25px
  @include first-breakpoint
    padding: 0 5%
  @include second-breakpoint
    padding: 0 3%
    flex-wrap: wrap
    .footer-content__column
      width: 100%
      padding: 0 2%
      a
        display: block
      dl
        dt,dd
          //background: $color_black
          padding: 12px $paddingBase 7px $paddingBase
          margin: $paddingBase*0.2 0
      &:not(.contact)
        a
          padding: 12px $paddingBase
          //background: $color_black
        ul
          li
            width: 100%
            padding: $paddingBase*0.2 0
  @include third-breakpoint
    text-align: center
    .footer-content__column
      dl
        flex-wrap: wrap
        dt
          width: 40px
        dd
          width: calc(100% - 40px)
      &.footer-menu
        ul
          li
            padding-bottom: $paddingBase
            a
              background: darken($color_orange01, 3)

      &:not(.contact)
        ul
          display: block
  @include smallest-view
    .footer-content__column
      width: 100%
      &.contact
        width: 100%
