.breadcrumb
  padding-top: $paddingBase
  @include first-breakpoint
    padding: $paddingBase $paddingBase 0 $paddingBase
  ol
    display: flex
    @include first-breakpoint
      flex-wrap: wrap
    list-style: none
    justify-content: center
    li
      span
        font-weight: bold
      a
        text-decoration: underline
        &:hover
          text-decoration: none
      i
        font-size: 25px
      &:before
        @include addIconThemify($ti-arrow-right)
        padding-left: $paddingBase
        padding-right: $paddingBase
        position: relative
        top: 2px
      &.breadcrumb__homepage
        &:before
          display: none