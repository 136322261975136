.big-banner
  width: 100%
  position: relative
  height: 45vw

  &__content
    $this: &

    height: 100%
    position: relative
    z-index: 2

    #{$this}--button

      @include smallest-view
        text-align: center

      a
        @include button(primary)

        @include second-breakpoint
          @include setFontSize(20)

        @include third-breakpoint
          @include setFontSize(16)


    #{$this}--text
      color: $color_white
      font-family: $fontFamilyBaseRuddy
      font-weight: bold
      @include setFontSize(70)
      width: 600px
      padding-bottom: $paddingBase*5

      @include second-breakpoint
        width: 350px
        @include setFontSize(40)
        padding-bottom: $paddingBase*3

      @include third-breakpoint
        width: 200px
        @include setFontSize(25)
        padding-bottom: $paddingBase*2

      @include smallest-view
        width: 100%
        text-align: center

    #{$this}--position-container
      position: absolute
      bottom: 30%
      right: 0

      @include third-breakpoint
        right: 10%

      @include smallest-view
        width: 100%
        right: 0

  &__img-cover
    position: absolute
    left: 0
    right: 0
    bottom: 0
    top: 0
    z-index: 1

    img
      object-fit: cover
      object-position: center top
      width: 100%
      height: 100%