@import 'front/_variables/variables'
@import 'addons/animations'
@import 'front/mixins/responsive-mixins'
@import 'front/mixins/typography'
@import 'addons/css-reset'
@import 'addons/clearfix'
@import 'addons/basic'
@import 'front/components/elements/button'
@import 'front/components/elements/headline'
@import 'front/components/news-carousel'
@import './assets/external/baguetteBox/css/baguetteBox'
@import './assets/external/splide/css/splite'
@import 'front/base-elements'
@import 'front/components/big-banner'
@import 'front/modal-window'
@import 'front/carousel'
@import 'front/breadcrumb'
@import 'front/header'
@import 'front/headline-content-banner'
@import 'front/logo'
//@import 'front/paging-mobile-menu'
@import 'front/menu'
//@import 'front/menu-homepage-icons'
//@import 'front/header-banner'
@import 'front/search/search-button'
//@import 'front/video-catalog/video-catalog'
@import 'front/audio-catalog/audio-catalog'
//@import 'front/eshop/eshop'
@import 'front/gallery'
@import 'front/form'
@import './assets/external/cookieconsent/css/cookieconsent'

//@import 'front/document-library/category-list'
//@import 'front/document-library/item-list'
//@import 'front/place/map'
//@import 'front/place/map-for-detail'
//@import 'front/from-login-simple-security'

.headline-with-cloud
  @include headlineWithCloud()

.headline-with-elipse
  @include headlineWithElipse()

h1
  color: $color_green04
  font-weight: 400
  border-bottom: 2px solid $color_green04
  text-align: center
  padding: $paddingBase*4 0 $paddingBase 0
  margin-bottom: $paddingBase*2

.homepage-padding-content
  padding: $paddingBase*7 0

  .headline-with-cloud
    margin-bottom: $paddingBase*4

.link-as-button
  display: inline-block
  background: $color_green04
  color: $color_white
  transition: 300ms
  padding: $paddingBase $paddingBase*3
  text-decoration: none
  border-radius: 5px
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px

  &:hover
    background: darken($color_green04, 7)
    color: $color_white
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px

  i
    color: $color_white
    padding-right: $paddingBase

.homepage-middle-content
  display: flex
  justify-content: space-between

  @include first-breakpoint
    margin: 0 $paddingBase*2

  @include second-breakpoint
    flex-wrap: wrap

  &__left
    width: 60%
    @include second-breakpoint
      width: 100%

  &__right
    width: 30%
    @include second-breakpoint
      width: 100%

.basic-page

  img
    height: auto

@import 'front/footer/footer'

//html, body
  background-color: $color_gray15
