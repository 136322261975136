#myProgress
  width: 100%
  background-color: $color_gray09
  cursor: pointer
  border-radius: 10px

#myBar
  width: 0
  height: 10px
  background-color: $color_green04
  border-radius: 10px

.logo
  display: none

.btn-action
  cursor: pointer
  padding-top: 10px
  width: 30px

.btn-ctn
  display: flex
  align-items: center
  justify-content: center

.infos-ctn
  text-align: center
  //display: flex
  //align-items: center
  //justify-content: center
  //padding-top: 20px

.btn-ctn > div
  padding: $paddingBase*1.5 $paddingBase
  //margin-top: 18px
  //margin-bottom: 18px

.infos-ctn > div
  margin-bottom: 8px
  color: $color_green04

.first-btn
  margin-left: 3px

.duration
  margin-left: 10px
  display: none

.timer
  @include setFontSize(16)

.title
  //margin-left: 10px
  //width: 210px
  text-align: center
  @include setFontSize(30)

.player-ctn
  border-radius: 5px
  width: 420px
  padding: 10px
  background-color: $color_white
  border: 4px solid $color_green04
  margin: 0

  @include second-breakpoint
    width: 100%

.playlist-track-ctn
  display: flex
  background-color: transparent
  border: 1px solid $color_green04
  margin-top: 3px
  border-radius: 5px
  cursor: pointer
  align-items: center

  //&:last-child
  /*border: 1px solid #ffc266;

  > div
    margin: $paddingBase*0.5 $paddingBase

.playlist-info-track
  width: 80%
  padding-top: 7px
  padding-bottom: 7px
  color: $color_green04
  font-size: 14px
  pointer-events: none

.playlist-duration
  padding-top: 7px
  padding-bottom: 7px
  color: $color_green04
  font-size: 14px
  pointer-events: none
  //display: none

.playlist-ctn
  padding-bottom: 20px

  @include min-second-breakpoint
    max-height: 500px
    overflow-y: auto

.active-track
  background: $color_green04
  color: $color_white !important
  font-weight: bold

  .playlist-btn-play,
  .playlist-download
    i
      color: $color_white

  >
    .playlist-info-track, .playlist-duration, .playlist-btn-play, .playlist-download
      color: $color_white !important

.playlist-btn-play,
.playlist-download
  pointer-events: none
  padding-top: 5px
  padding-bottom: 5px

.fas
  color: $color_green04
  font-size: 20px
