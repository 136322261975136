// default width of design
$defaultWidth: 1360px

// base space value
$paddingBase: 10px

// max. width for modal window
$maxModalWindowWidth: 700px

// breakpoints mixins
$fullViewBreakpoint: 1360px
$firstBreakpoint: 1000px
$secondAndHalfBreakpoint: 950px
$secondBreakpoint: 730px
$smallestBreakpoint: 550px