.footer-subscribe-newsletter
  text-align: center
  border-bottom: 1px solid $color_gray10
  padding: $paddingBase*2 0 $paddingBase*3 0
  margin-bottom: $paddingBase*3
  .footer-subscribe-newsletter__form
    width: 40%
    margin: 0 auto
    padding-top: $paddingBase*2
    text-align: left
    a
      color: $color_gray09
    p
      @include setFontSize(12)
    .footer-subscribe-newsletter__form--elements
      display: flex
      input
        border: 1px solid $color_gray10
        flex-grow: 1
        padding: $paddingBase $paddingBase*2
        background: transparent
        color: $color_gray09
      button
        border: none
        background: $color_gray10
        color: $color_white
        text-transform: uppercase
        font-weight: bold
        padding: 0 $paddingBase*2
        cursor: pointer
        &:hover
          text-decoration: underline
    @include first-breakpoint
      width: 60%
    @include second-breakpoint
      width: 90%
    @include smallest-view
      .footer-subscribe-newsletter__form--elements
        flex-wrap: wrap
        input, button
          width: 100%
        button
          padding: $paddingBase*0.5 $paddingBase*2
          margin-top: $paddingBase
  @include second-breakpoint
    h2, p
      padding-left: 5%
      padding-right: 5%