@import "components/copyright"
@import "components/text"
@import "components/content"
@import "components/newsletter-form"

footer
  margin-top: $paddingBase*4
  color: $color_white
  h2
    text-transform: uppercase
    color: $color_white
    font-weight: 300
    @include setFontSize(25)