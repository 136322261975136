$color_white: #ffffff
$color_black: #000000
$color_gray01: #1c1c1c
$color_gray02: #f4f4f4
$color_gray02: #555555
$color_gray06: #d4d4d4
$color_gray07: #f1f1f1
$color_gray08: #1e1e1e
$color_gray09: #c2c2c2
$color_gray10: #323232
$color_gray11: #1f1e1e
$color_gray12: #e8e8e8
$color_gray13: #efefef
$color_gray14: #dbdbdb
$color_gray15: #ebeeee
$color_gray16: #a6a6a6
$color_purple01: #392e7a
$color_purple02: #5543c1
$color_red: #c62447
$color_red2: #ba455e
$color_red03: #d77a8e
$color_red04: #2F1218
$color_red05: #B21234
$color_red06: #AF0026
$color_red07: #ff0000
$color_green01: #86C232
$color_green02: #61892F
$color_green03: #53900F
$color_green04: #5cb0a5
$color_green05: #1ed760
$color_orange01: #F24C07
$color_orange02: #e05929
