.header__logo
  @include first-breakpoint
    width: 55%
  a
    font-size: 50px
    font-weight: 300

    height: 100px
    display: flex
    align-items: center
    text-decoration: none
    text-transform: uppercase
    @include first-breakpoint
      font-size: 20px
      height: 50px
      justify-content: center
      img
        max-height: 100%
    span
      padding-left: $paddingBase
      position: relative
      top: 5px

.logo-container
  $this: &
  $logoColor: $color_purple02

  font-family: $fontFamilyBaseRuddy

  #{$this}__link
    text-decoration: none
    color: $logoColor
    display: flex

    &:hover
      text-decoration: none !important

  #{$this}__text
    color: $logoColor
    text-transform: uppercase
    padding-top: 20px
    font-weight: bold
    font-size: 20px

  #{$this}__image

    img
      height: 70px
      width: auto

  &.header

    #{$this}__link,
    #{$this}__text
      color: $color_white

    #{$this}__text
      padding-top: 15px
      font-size: 17px

    #{$this}__image
      display: flex

      img
        height: 80px
